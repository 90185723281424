import React, { FC } from "react";
import { useTimeEntryTypesVM } from "../ViewModel/TimeEntryTypesVM";
import { FormHeader } from "../../../components/FormHeader/View/FormHeader";
import { Button } from "antd";
import { FaPlus } from "react-icons/fa";
import { TimeEntryTypeCard } from "../../../components/TimeEntryTypeCard/View/TimeEntryTypeCard";

export const TimeEntryTypesView: FC = () => {
    const {
        timeEntryTypes,
        deleteTimeEntryTypeMutation,
        navigateToCreateTimeEntryType,
        navigateToEditTimeEntryType,
        navigateToSettings,
    } = useTimeEntryTypesVM();

    return (
        <>
            <FormHeader label={"Time Entry Types"} onReturn={navigateToSettings} />
            <div className={"container"}>
                {timeEntryTypes.map((timeEntryType, key) => {
                    return (
                        <TimeEntryTypeCard
                            key={key}
                            timeEntryType={timeEntryType}
                            onEdit={() => navigateToEditTimeEntryType(timeEntryType)}
                            onDelete={() => deleteTimeEntryTypeMutation.mutate(timeEntryType.id)}
                        />
                    );
                })}
                <Button type={"primary"} icon={<FaPlus />} onClick={navigateToCreateTimeEntryType}>
                    Add Time Entry Type
                </Button>
            </div>
        </>
    );
};
