import { useMemo } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { useNavigate } from "react-router-dom";
import { getProjects } from "../../../api/projectApi";
import { getTimeEntryTypes, TimeEntryTypeRecord } from "../../../api/timeEntryTypeApi";
import { useAppVM } from "../../../AppVM";
import { Project } from "../../../data/Project";
import { Form, FormInstance } from "antd";
import { TimeEntryType } from "../../../data/TimeEntryType";
import { defaultTimeEntryTypeColors } from "../../../util/timeEntryTypeColors";

interface TimesheetHeaderVM {
    projects: Project[];
    types: TimeEntryType[];
    navigateToCreateTimeEntry: () => void;
    form: FormInstance<any>;
}

export interface TimesheetHeaderFormValue {
    projectName: string;
    type: string;
    dateRange?: moment.Moment[];
    pageSize: number;
    sortOrder: "asc" | "desc";
}

export const useTimesheetHeaderVM = (): TimesheetHeaderVM => {
    const navigate = useNavigate();
    const { routes } = useAppVM();
    const [form] = Form.useForm();

    const navigateToCreateTimeEntry = () => {
        navigate(`${routes.timesheet}/create`);
    };

    const projectQuery: UseQueryResult<Project[], Error> = useQuery("Projects", () => getProjects());

    const projects: Project[] = useMemo<Project[]>(() => {
        if (projectQuery.isFetched && projectQuery.isSuccess) {
            return projectQuery.data.map((project: Project) => {
                return project;
            });
        }
        return [];
    }, [projectQuery]);

    const typeQuery: UseQueryResult<TimeEntryTypeRecord[], Error> = useQuery("TimeEntryTypes", getTimeEntryTypes);

    const types: TimeEntryType[] = useMemo<TimeEntryType[]>(() => {
        if (typeQuery.isFetched && typeQuery.isSuccess) {
            return typeQuery.data.map((type: TimeEntryTypeRecord, index) => {
                return new TimeEntryType(type, defaultTimeEntryTypeColors[index]);
            });
        }
        return [];
    }, [typeQuery]);

    return {
        projects,
        types,
        navigateToCreateTimeEntry,
        form,
    };
};
