import React, { FC, useState } from "react";
import { useUsersVM } from "../ViewModel/UsersVM";
import { FormHeader } from "../../../components/FormHeader/View/FormHeader";
import { UserCard } from "../../../components/UserCard/View/UserCard";
import "./UsersView.css";
import { ViewMode } from "../../../util/viewModeUtil";
import { Button, Input, Select } from "antd";
import { Option } from "antd/lib/mentions";
import { UserTable } from "../../../components/UserTable/View/UserTable";

export const UsersView: FC = () => {
    const { users, deleteUserMutation, navigateToEditUser, navigateToUserInfo, navigateToSettings, setSearchWord } =
        useUsersVM();
    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Card);
    const [searchInput, setSearchInput] = useState<string>("");

    return (
        <div className="users-view">
            <FormHeader label={"Users"} onReturn={() => navigateToSettings()} />
            <div className="users-options-container">
                <div className="users-search-container">
                    <div className="users-search-form-container">
                        <Input placeholder="Search users" onChange={(e) => setSearchInput(e.target.value)} />
                        <Button onClick={() => setSearchWord(searchInput)}>Search</Button>
                    </div>
                    <p>Total: {users.length}</p>
                </div>
                <div>
                    <Select
                        className="view-select"
                        defaultValue={viewMode}
                        onChange={(value: ViewMode) => setViewMode(value)}
                    >
                        <Option value={ViewMode.Card}>Card view</Option>
                        <Option value={ViewMode.Table}>Table view</Option>
                    </Select>
                </div>
            </div>

            <div className="users-body">
                {viewMode === ViewMode.Card ? (
                    <div className={"users-container"}>
                        {users.map((user, key) => {
                            return (
                                <UserCard
                                    User={user}
                                    key={key}
                                    onDelete={() => deleteUserMutation.mutate(user.id)}
                                    onEdit={() => navigateToEditUser(user)}
                                    onInfo={() => navigateToUserInfo(user)}
                                />
                            );
                        })}
                    </div>
                ) : undefined}

                {viewMode === ViewMode.Table ? (
                    <UserTable users={users} onDelete={deleteUserMutation.mutate} />
                ) : undefined}
            </div>
        </div>
    );
};
