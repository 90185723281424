const SECONDS_TO_HOURS_OFFSET = 3600;
const TWO_DIGITS_PADDING = "0";

export function formatDate(date: Date): string {
    return [padToTwoDigits(date.getMonth() + 1), padToTwoDigits(date.getDate()), date.getFullYear()].join("/");
}

// From MM/DD/YYYY format to YYYY-MM-DD
export function convertDateFormat(inputDate: string) {
    const parts = inputDate.split("/");
    if (parts.length === 3) {
        const year = parts[2];
        const month = parts[0].padStart(2, "0");
        const day = parts[1].padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    return inputDate;
}

function padToTwoDigits(num: number): string {
    return num.toString().padStart(2, TWO_DIGITS_PADDING);
}

export function convertSecondsToHours(seconds: number): number {
    return seconds / SECONDS_TO_HOURS_OFFSET;
}

export function convertHoursToSeconds(hours: number): number {
    return hours * SECONDS_TO_HOURS_OFFSET;
}
