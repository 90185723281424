import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import UserContextProvider from "./context/UserContextProvider";
import CalendarContextProvider from "./context/CalendarContextProvider";
import { ReportsContextProvider } from "./context/ReportsContextProvider";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        <UserContextProvider>
            <CalendarContextProvider>
                <ReportsContextProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ReportsContextProvider>
            </CalendarContextProvider>
        </UserContextProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
