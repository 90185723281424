import React, { useState } from "react";

interface AppVM {
    mainContainerClassName: string;
    setMainContainerClassName: React.Dispatch<React.SetStateAction<string>>;
    routes: RoutePaths;
}

export interface RoutePaths {
    signIn: string;
    signInCallback: string;
    home: string;
    timesheet: string;
    reports: string;
    settings: string;
    projects: string;
    users: string;
    timeEntryTypes: string;
    projectMembers: string;
    calendar: string;
}

export function useAppVM(): AppVM {
    const [mainContainerClassName, setMainContainerClassName] = useState("main-container");

    const routes: RoutePaths = {
        signIn: "/auth",
        signInCallback: "/auth/google",
        home: "/",
        timesheet: "/timesheet",
        reports: "/reports",
        settings: "/settings",
        projects: "/settings/projects",
        users: "/settings/users",
        timeEntryTypes: "/settings/timeEntryTypes",
        projectMembers: "/settings/projectMembers",
        calendar: "/calendar",
    };

    return {
        mainContainerClassName,
        setMainContainerClassName,
        routes,
    };
}
