import React, { useContext, useEffect, useState } from "react";
import { FaBook, FaCalendar, FaHome, FaGripHorizontal, FaCalendarAlt } from "react-icons/fa";
import { IconType } from "react-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext, UserContextType } from "../../../context/UserContextProvider";
import { User } from "../../../api/userApi";

interface NavigationVM {
    isNavbarExpanded: boolean;
    setIsNavbarExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    buttonsState: NavigationButtonState[];
    onNavigationButtonClick: (key: number) => void;
    navigation: (route: string) => void;
    navigationClassName: string;
    setNavigationClassName: React.Dispatch<React.SetStateAction<string>>;
    navigationClassNames: NavigationClassNames;
    mainContainerClassNames: MainContainerClassNames;
    user: User | null;
}

interface NavigationButtonState {
    label: string;
    isClicked: boolean;
    icon: IconType;
    route: string;
}

interface NavigationClassNames {
    navContainer: string;
    navContainerLocked: string;
    navContainerExpandedLocked: string;
}

interface MainContainerClassNames {
    mainContainer: string;
    mainContainerExpanded: string;
}

export const useNavigationVM = (): NavigationVM => {
    const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);

    const location = useLocation();

    const { user } = useContext(UserContext) as UserContextType;

    const initialButtonsState: NavigationButtonState[] = [
        {
            label: "Home",
            isClicked: false,
            icon: FaHome,
            route: "/",
        },
        {
            label: "Timesheet",
            isClicked: false,
            icon: FaCalendar,
            route: "/timesheet",
        },
        {
            label: "Calendar",
            isClicked: false,
            icon: FaCalendarAlt,
            route: "/calendar",
        },
    ];

    if (user?.role_id === 2 || user?.role_id === 1) {
        initialButtonsState.push({
            label: "Reports",
            isClicked: false,
            icon: FaBook,
            route: "/reports",
        });
    }

    if (user?.role_id === 1) {
        initialButtonsState.push({
            label: "Settings",
            isClicked: false,
            icon: FaGripHorizontal,
            route: "/settings",
        });
    }

    const [buttonsState, setButtonsState] = useState<NavigationButtonState[]>(initialButtonsState);

    useEffect(() => {
        setButtonsState(initialButtonsState);
    }, [user]);

    const [navigationClassName, setNavigationClassName] = useState("nav-container");

    const navigationClassNames = {
        navContainer: "nav-container",
        navContainerLocked: "nav-container nav-container-locked",
        navContainerExpandedLocked: "nav-container nav-container-expanded-locked",
    };

    const mainContainerClassNames = {
        mainContainer: "main-container",
        mainContainerExpanded: "main-container-nav-expanded",
    };

    const navigation = useNavigate();

    const onNavigationButtonClick = (key: number) => {
        const newButtonsState: NavigationButtonState[] = [];
        buttonsState.map((item, index) => {
            let newItem;

            if (index !== key) {
                newItem = { ...item, isClicked: false };
            } else newItem = { ...item, isClicked: true };

            newButtonsState.push(newItem);
        });
        setButtonsState(newButtonsState);
    };

    useEffect(() => {
        const newButtonsState: NavigationButtonState[] = [];
        buttonsState.map((item) => {
            let newItem;
            if (location.pathname === "/" && item.route === "/") {
                newItem = { ...item, isClicked: true };
            } else if (location.pathname.startsWith(item.route) && item.route !== "/") {
                newItem = { ...item, isClicked: true };
            } else newItem = { ...item, isClicked: false };
            newButtonsState.push(newItem);
        });
        setButtonsState(newButtonsState);
    }, []);

    return {
        isNavbarExpanded,
        setIsNavbarExpanded,
        buttonsState,
        navigation,
        navigationClassName,
        navigationClassNames,
        setNavigationClassName,
        mainContainerClassNames,
        onNavigationButtonClick,
        user,
    };
};
