import { client } from "./client";

export interface Team {
    id: number;
    name: string;
    description: string;
    project_id: number;
}

interface TeamsData {
    data: Team[];
}

export async function getTeams(project_id: number | undefined): Promise<Team[]> {
    const requestBody = {
        project_id: project_id,
    };
    const response = await client.get<TeamsData>("/team", { params: requestBody });
    return response.data.data;
}

export async function getTeamById(id: number): Promise<Team> {
    const response = await client.get<Team>(`/team/${id}`);
    return response.data;
}

export async function addTeam(team: Team): Promise<void> {
    await client.post<Team>(`/team`, team);
}

export async function editTeam(team: Team): Promise<void> {
    await client.put<Team>(`/team/${team.id}`, team);
}

export async function deleteTeam(id: number): Promise<void> {
    await client.delete<Team>(`/team/${id}`);
}
