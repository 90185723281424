import React from "react";
import { FormHeader } from "../../../components/FormHeader/View/FormHeader";
import { Button, Input, Form } from "antd";
import { FaEdit } from "react-icons/fa";
import { useProjectInfoVM } from "../ViewModel/ProjectInfoVM";
import { TeamCard } from "../../../components/TeamCard/View/TeamCard";
import { Team } from "../../../api/teamApi";

export const ProjectInfo = () => {
    const { project, navigateToEditProject, navigateToProjects, teams, deleteTeamMutation, navigateToEditTeam } =
        useProjectInfoVM();
    const { TextArea } = Input;

    return (
        <>
            <FormHeader label={"Project Info"} onReturn={() => navigateToProjects()} />
            <div className={"form-container"}>
                <div className={"form-centered"}>
                    <Form>
                        <Form.Item>
                            <h3>Project name</h3>
                            <Input placeholder={"Project"} size={"middle"} value={project.name} readOnly />
                        </Form.Item>
                        <Form.Item>
                            <h3>Project description</h3>
                            <TextArea
                                rows={5}
                                placeholder={"Project"}
                                size={"middle"}
                                value={project.description}
                                readOnly
                            />
                        </Form.Item>
                        <div>
                            <h2>Teams</h2>
                            {teams.length > 0 ? (
                                teams.map((team: Team) => (
                                    <TeamCard
                                        key={team.id}
                                        team={team}
                                        onDelete={() => deleteTeamMutation.mutate(team.id)}
                                        onEdit={() => navigateToEditTeam(team)}
                                    />
                                ))
                            ) : (
                                <p>No teams</p>
                            )}
                        </div>

                        <Form.Item>
                            <div className={"inline-buttons-container"}>
                                <Button
                                    type={"primary"}
                                    icon={<FaEdit />}
                                    onClick={() => navigateToEditProject(project)}
                                >
                                    Edit Project
                                </Button>
                                {/* <Button
                                    type={"default"}
                                    icon={<FaUser />}
                                    onClick={() => console.log("navigate to users")}
                                >
                                    See Users
                                </Button> */}
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    );
};
