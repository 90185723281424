import React from "react";
import { Select } from "antd";
import "./TimeInputStyle.css";
import {
    changeNumberAfterDecimal,
    changeNumberBeforeDecimal,
    getHoursFromNumber,
    getRoundedMinutesFromNumber,
} from "../../../util/timeDurationConversionUtil";

interface TimeInputProps {
    onChange?: (value: number) => void;
    value?: number | null;
}

export const TimeInput = (props: TimeInputProps): JSX.Element => {
    const { Option } = Select;

    const minutes = [0, 15, 30, 45];

    return (
        <div className={"time-inputs-container"}>
            <div className={"labeled-input"}>
                <h3>Hours</h3>
                <Select
                    placeholder={"Hours"}
                    value={props.value ? getHoursFromNumber(props.value) : 8}
                    onChange={(value) => {
                        let newDuration;
                        if (props.value && props.onChange) {
                            newDuration = changeNumberBeforeDecimal(props.value, value);
                            props.onChange(newDuration);
                        } else if (props.onChange) {
                            newDuration = changeNumberBeforeDecimal(0, value);
                            props.onChange(newDuration);
                        }
                    }}
                >
                    {[...(Array(13) as undefined[])].map((item, index) => (
                        <Option key={index} value={index}>
                            {index}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className={"labeled-input"}>
                <h3>Minutes</h3>
                <Select
                    placeholder={"Minutes"}
                    value={props.value ? getRoundedMinutesFromNumber(props.value) : 0}
                    defaultValue={0}
                    onChange={(value) => {
                        let newDuration;
                        if (props.value && props.onChange) {
                            newDuration = changeNumberAfterDecimal(props.value, value);
                            props.onChange(newDuration);
                        } else if (props.onChange) {
                            newDuration = changeNumberAfterDecimal(0, value);
                            props.onChange(newDuration);
                        }
                    }}
                >
                    {minutes.map((item) => (
                        <Option key={item} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    );
};
