import React, { Dispatch, useEffect } from "react";
import "./ReportsHeaderStyle.css";
import { Select, DatePicker, Form, Tooltip } from "antd";
import { IconButton } from "../../IconButton/View/IconButton";
import { FaCalendar, FaSearch } from "react-icons/fa";
import { Header } from "../../Header/View/Header";
import { ReportsHeaderFormValue, useReportsHeaderVM } from "../ViewModel/ReportsHeaderVM";
import { DurationData } from "../../../util/hooks/useTimeEntriesDuration";
import { TotalCard } from "../../TotalCard/View/TotalCard";
import { ReportsFilters } from "../../../context/ReportsContextProvider";

interface ReportsHeaderProps {
    durationData: DurationData;
    setReportsFilters: Dispatch<React.SetStateAction<ReportsFilters>>;
    reportsFilters: ReportsFilters;
}

export const ReportsHeader = (props: ReportsHeaderProps): JSX.Element => {
    const { Option } = Select;
    const { projects, types, users, form } = useReportsHeaderVM();

    useEffect(() => {
        // Set default form field values based on reportsFilters when component mounts
        form.setFieldsValue({
            dateRange: props.reportsFilters.dateRange,
            projectName: props.reportsFilters.project,
            type: props.reportsFilters.type,
            user: props.reportsFilters.username,
            pageSize: props.reportsFilters.pageSize,
            sortOrder: props.reportsFilters.sortOrder,
        });
    }, [props.reportsFilters, form]);

    return (
        <Header>
            <div className={"input-bar"}>
                <Form name={"ReportsHeaderForm"} layout={"inline"} form={form}>
                    <Form.Item name={"dateRange"}>
                        <DatePicker.RangePicker
                            suffixIcon={<FaCalendar />}
                            onChange={(value) => {
                                if (value) {
                                    form.setFieldsValue({
                                        dateRange: value,
                                    });
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item name="projectName">
                        <Select
                            placeholder={"Project"}
                            size={"middle"}
                            onChange={(value) => {
                                form.setFieldsValue({ projectName: value as string });
                            }}
                            allowClear
                        >
                            {projects.map((project) => {
                                return (
                                    <Option key={project.id} value={project.name}>
                                        {project.name}
                                    </Option>
                                );
                            })}
                            <Option key={"Research"} value={"Research"}>
                                {"Research"}
                            </Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="type">
                        <Select placeholder={"Type"} allowClear>
                            {types.map((type) => (
                                <Option key={type.id} value={type.name}>
                                    <Tooltip placement="right" title={type.description}>
                                        <span>{type.name}</span>
                                    </Tooltip>
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="user">
                        <Select placeholder={"User"} allowClear>
                            {users.map((user) => {
                                return (
                                    <Option key={user.id} value={user.email}>
                                        {user.email}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item name="pageSize">
                        <Select placeholder={"Page Size"}>
                            <Option value={30}>30</Option>
                            <Option value={50}>50</Option>
                            <Option value={100}>100</Option>
                            <Option value={150}>150</Option>
                            <Option value={200}>200</Option>
                            <Option value={300}>300</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="sortOrder">
                        <Select placeholder={"Sort by date"}>
                            <Option value="asc">Ascending</Option>
                            <Option value="desc">Descending</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <IconButton
                            icon={FaSearch}
                            onClick={() => {
                                const formFieldsValue = form.getFieldsValue() as ReportsHeaderFormValue;
                                const filters: ReportsFilters = {
                                    username: formFieldsValue.user,
                                    project: formFieldsValue.projectName,
                                    type: formFieldsValue.type,
                                    dateRange: !!formFieldsValue.dateRange ? formFieldsValue.dateRange : undefined,
                                    pageSize: !!formFieldsValue.pageSize
                                        ? formFieldsValue.pageSize
                                        : props.reportsFilters.pageSize,
                                    sortOrder: formFieldsValue.sortOrder,
                                };

                                localStorage.setItem("reportFilters", JSON.stringify(filters));
                                props.setReportsFilters(filters);
                            }}
                        />
                    </Form.Item>
                </Form>
            </div>
            <TotalCard durationData={props.durationData} />
        </Header>
    );
};
