import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import { Team } from "../../../api/teamApi";
import { useNavigate } from "react-router-dom";
import { useAppVM } from "../../../AppVM";
import { useMemo } from "react";
import { getTeamMemberships, TeamMembership, deleteTeamMembership } from "../../../api/teamMembershipsApi";

interface TeamCardVM {
    navigateToSettings: () => void;
    teamMemberships: TeamMembership[];
    deleteTeamMembershipMutation: UseMutationResult<void, unknown, number, unknown>;
}

interface TeamCardVMProps {
    team: Team;
}

export function useTeamCardVM(props: TeamCardVMProps): TeamCardVM {
    // const TeamsQuery: UseQueryResult<Team[], Error> = useQuery("TeamMemberships", () => getTeamMemberships(props.team?.id));

    // const location = useLocation();
    const navigate = useNavigate();
    const { routes } = useAppVM();

    const queryClient = useQueryClient();

    const navigateToSettings = () => {
        navigate(routes.settings);
    };

    const projectMembershipQuery: UseQueryResult<TeamMembership[], Error> = useQuery(
        `TeamMemberships${props.team.id}`,
        () => getTeamMemberships(props.team.id)
    );

    const teamMemberships: TeamMembership[] = useMemo<TeamMembership[]>(() => {
        if (projectMembershipQuery.isFetched && projectMembershipQuery.isSuccess) {
            return projectMembershipQuery.data;
        }
        return [];
    }, [projectMembershipQuery]);

    const deleteTeamMembershipMutation = useMutation(deleteTeamMembership, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([`TeamMemberships${props.team.id}`]);
        },
    });

    return {
        // error: TeamsQuery.error,
        // loading: TeamsQuery.isLoading,
        // deleteTeamMutation,
        // navigateToCreateTeam,
        // navigateToEditTeam,
        // navigateToTeamInfo,
        navigateToSettings,
        teamMemberships,
        deleteTeamMembershipMutation,
    };
}
