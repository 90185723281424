import React, { FC, useState } from "react";
import { User, roleMapping } from "../../../api/userApi";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import { useAppVM } from "../../../AppVM";
import { DeleteConfirmationModal } from "../../DeleteConfirmationModal/View/DeleteConfirmationModal";

export interface UserTableProps {
    users: User[];
    onDelete: (id: number) => void;
}

export const UserTable: FC<UserTableProps> = (props: UserTableProps) => {
    const navigate = useNavigate();
    const { routes } = useAppVM();
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User | undefined>();

    const tableColumns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "Role",
            dataIndex: "",
            key: "role_id",
            render: (record: User) => <>{roleMapping.get(record.role_id.toString())}</>,
        },
        {
            title: "Active",
            dataIndex: "",
            key: "active",
            render: (record: User) => <>{record.deactivated ? "❌" : "✅"}</>,
        },
        {
            title: "Action",
            dataIndex: "",
            key: "x",
            render: (record: User) => (
                <div className={"primary-card-content-action-panel"}>
                    <button
                        className={"primary-card-edit-btn"}
                        onClick={() =>
                            navigate(`${routes.users}/edit/${record.id}`, {
                                state: {
                                    ...record,
                                },
                            })
                        }
                    >
                        <FaEdit />
                    </button>
                    <button
                        className={"primary-card-delete-btn"}
                        onClick={() => {
                            setSelectedUser(record);
                            setDeleteConfirmationOpen(true);
                        }}
                    >
                        <FaTrash />
                    </button>
                </div>
            ),
        },
    ];

    return (
        <>
            {deleteConfirmationOpen && (
                <DeleteConfirmationModal
                    text={`Are you sure you want to delete ${selectedUser ? selectedUser.email : ""}?`}
                    onAccept={() => {
                        props.onDelete(selectedUser ? selectedUser.id : 0);
                        setDeleteConfirmationOpen(false);
                    }}
                    onDecline={() => setDeleteConfirmationOpen(false)}
                />
            )}

            <Table
                dataSource={props.users}
                columns={tableColumns}
                pagination={false}
                size={"middle"}
                scroll={{ y: "80vh" }}
            />
        </>
    );
};
