import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { UserContextType, UserContext } from "../../context/UserContextProvider";
import { getUser } from "../../api/userApi";

interface ProtectedRouteProps {
    children: ReactNode;
    allowedRoles: number[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, allowedRoles: requiredRoles }) => {
    const { user, setUser } = useContext(UserContext) as UserContextType;

    const [loadingUser, setLoadingUser] = useState<boolean>(true);

    useEffect(() => {
        const checkUser = async () => {
            if (localStorage.getItem("access_token")) {
                if (!user) {
                    try {
                        setLoadingUser(true);
                        const currentUser = await getUser();
                        if (currentUser) {
                            setUser(currentUser);
                        }
                    } catch (error) {
                        console.error("Error fetching user:", error);
                    }
                }
            }
            setLoadingUser(false);
        };

        checkUser().catch((error) => console.error("Error checking user:", error));
    }, []);

    if ((!localStorage.getItem("access_token") || user === null) && !loadingUser) {
        return <Navigate to="/auth" replace />;
    }

    //  check if user is the correct role to access the page
    if (user !== null && !requiredRoles.includes(user.role_id)) {
        return <Navigate to="/" replace />;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
