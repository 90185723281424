import React from "react";
import "./NavigationButtonStyle.css";
import { useNavigationButton } from "../ViewModel/NavigationButtonVM";

interface NavigationButtonProps {
    children: React.ReactNode;
    label: string;
    isClicked: boolean;
    expanded: boolean;
    onClick: () => void;
}

export const NavigationButton = (props: NavigationButtonProps): JSX.Element => {
    const { getClassName } = useNavigationButton({
        expanded: props.expanded,
        isClicked: props.isClicked,
    });

    return (
        <button className={getClassName()} onClick={props.onClick}>
            <div className={"content"}>
                {props.children}
                <div className={"nav-btn-label"}>{props.label}</div>
            </div>
        </button>
    );
};
