import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "react-query";
import { deleteProject, getAllProjects, ProjectRecord } from "../../../api/projectApi";
import { useNavigate } from "react-router-dom";
import { useAppVM } from "../../../AppVM";
import { Project } from "../../../data/Project";
import { useMemo, useState } from "react";

interface ProjectsVM {
    error: null | Error;
    loading: boolean;
    projects: Project[];
    deleteProjectMutation: UseMutationResult<void, unknown, number, unknown>;
    navigateToCreateProject: () => void;
    navigateToEditProject: (project: Project) => void;
    navigateToProjectInfo: (project: Project) => void;
    navigateToSettings: () => void;
    showArchived: boolean;
    setShowArchived: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useProjectsVM(): ProjectsVM {
    const [showArchived, setShowArchived] = useState<boolean>(false);

    const projectsQuery: UseQueryResult<ProjectRecord[], Error> = useQuery(["Projects", showArchived], () =>
        getAllProjects(showArchived ? 1 : 0)
    );

    // const location = useLocation();
    const navigate = useNavigate();
    const { routes } = useAppVM();

    const queryClient = useQueryClient();

    const projects: Project[] = useMemo<Project[]>(() => {
        if (projectsQuery.isFetched && projectsQuery.isSuccess) {
            return projectsQuery.data.map((record: ProjectRecord) => {
                return new Project(record);
            });
        }
        return [];
    }, [projectsQuery]);

    const deleteProjectMutation = useMutation(deleteProject, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["Projects"]);
        },
    });

    const navigateToCreateProject = () => {
        navigate(`${routes.projects}/create`);
    };

    const navigateToEditProject = (project: Project) => {
        navigate(`${routes.projects}/edit/${project.id}`, {
            state: {
                ...project,
            },
        });
    };

    const navigateToSettings = () => {
        navigate(routes.settings);
    };

    const navigateToProjectInfo = (project: Project) => {
        navigate(`${routes.projects}/${project.id}`, {
            state: {
                ...project,
            },
        });
    };

    return {
        error: projectsQuery.error,
        loading: projectsQuery.isLoading,
        projects: projects || [],
        deleteProjectMutation,
        navigateToCreateProject,
        navigateToEditProject,
        navigateToProjectInfo,
        navigateToSettings,
        showArchived,
        setShowArchived,
    };
}
