export function getHoursFromNumber(num: number) {
    if (Number.isInteger(num)) {
        return num;
    }

    return parseInt(num.toString().split(".")[0], 10);
}

export function getRoundedMinutesFromNumber(num: number) {
    const n = getMinutesFromNumber(num);
    return roundNumberToTimeInputMinutes(n);
}

function getMinutesFromNumber(num: number) {
    if (Number.isInteger(num)) {
        return 0;
    }

    const formattedNumber = (Math.round(num * 100) / 100).toFixed(2);
    const afterDecimalString = formattedNumber.toString().split(".")[1];
    const afterDecimalNumber = parseInt(afterDecimalString, 10);

    return afterDecimalNumber;
}

function roundNumberToTimeInputMinutes(num: number) {
    if (num < 7.5) {
        return 0;
    } else if (num < 22.5) {
        return 15;
    } else if (num < 37.5) {
        return 30;
    } else return 45;
}

export function changeNumberBeforeDecimal(initialNumber: number, newNumberBeforeDecimal: number): number {
    const formattedNumber = (Math.round(initialNumber * 100) / 100).toFixed(2);
    const afterDecimalString = formattedNumber.toString().split(".")[1];
    const newNumberString = `${newNumberBeforeDecimal}.${afterDecimalString}`;

    return Number(newNumberString);
}

export function changeNumberAfterDecimal(initialNumber: number, newNumberAfterDecimal: number): number {
    const formattedNumber = (Math.round(initialNumber * 100) / 100).toFixed(2);
    const beforeDecimalString = formattedNumber.toString().split(".")[0];
    const newNumberString = `${beforeDecimalString}.${newNumberAfterDecimal}`;

    return Number(newNumberString);
}

export function getHoursAndMinutesFromNumber(num: number): string {
    const hours = getHoursFromNumber(num);
    const minutes = getRoundedMinutesFromNumber(num);

    if (minutes) {
        if (hours) {
            return `${hours}h ${minutes}m`;
        }
        return `${minutes}m`;
    }
    return `${hours}h`;
}

export const getSumOfDuration = (x: number, y: number) => {
    const xDuration = [getHoursFromNumber(x), getMinutesFromNumber(x)];
    const yDuration = [getHoursFromNumber(y), getMinutesFromNumber(y)];

    let sumHours = 0;
    let sumMinutes = xDuration[1] + yDuration[1];
    if (sumMinutes >= 60) {
        sumHours++;
        sumMinutes = sumMinutes - 60;
    }
    sumHours += xDuration[0] + yDuration[0];

    return Number(`${sumHours}.${sumMinutes}`);
};
