interface NavigationButtonVM {
    getClassName: () =>
        | "nav-btn nav-btn-active nav-btn-expanded"
        | "nav-btn nav-btn-active"
        | "nav-btn nav-btn-expanded"
        | "nav-btn";
}

interface NavigationButtonVMProps {
    expanded: boolean;
    isClicked: boolean;
}

export const useNavigationButton = (props: NavigationButtonVMProps): NavigationButtonVM => {
    const getClassName = () => {
        if (props.isClicked) {
            if (props.expanded) {
                return "nav-btn nav-btn-active nav-btn-expanded";
            }
            return "nav-btn nav-btn-active";
        } else {
            if (props.expanded) {
                return "nav-btn nav-btn-expanded";
            }
            return "nav-btn";
        }
    };

    return {
        getClassName,
    };
};
