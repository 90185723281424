import React, { useState } from "react";
import { FaEdit, FaTrash, FaAngleUp, FaAngleDown } from "react-icons/fa";
import { TimeEntry } from "../../../data/TimeEntry";
import { getHoursAndMinutesFromNumber } from "../../../util/timeDurationConversionUtil";
import { useExpansion } from "../../../util/hooks/useExpansion";
import { DeleteConfirmationModal } from "../../DeleteConfirmationModal/View/DeleteConfirmationModal";

interface TimeEntryCardProps {
    timeEntry: TimeEntry;
    color: string;
    onDelete: (id: number) => void;
    onEdit: () => void;
}

export const TimeEntryCard = (props: TimeEntryCardProps): JSX.Element => {
    const { isExpanded, setIsExpanded } = useExpansion();
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);

    return (
        <>
            {deleteConfirmationOpen && (
                <DeleteConfirmationModal
                    text={`Are you sure you want to delete time entry for ${props.timeEntry.startDateTimeStamp} by ${props.timeEntry.createdBy} ?`}
                    onAccept={() => {
                        props.onDelete(props.timeEntry.id);
                        setDeleteConfirmationOpen(false);
                    }}
                    onDecline={() => setDeleteConfirmationOpen(false)}
                />
            )}
            <div className={"primary-card-container"}>
                <div className={`primary-card-header ${props.color}`} onClick={() => setIsExpanded(!isExpanded)}>
                    {props.timeEntry.projectName}
                    {isExpanded ? <FaAngleUp /> : <FaAngleDown />}
                </div>
                <div className={"primary-card-content-time-entry"}>
                    <div className={"primary-card-content-item-centered"}>{props.timeEntry.startDateTimeStamp}</div>
                    <div className={"primary-card-content-item-centered"}>
                        {getHoursAndMinutesFromNumber(props.timeEntry.duration)}
                    </div>
                    <div className={"primary-card-content-item-centered"}>{props.timeEntry.username}</div>
                    <div className={"primary-card-content-item-centered"}>{props.timeEntry.type}</div>
                    <div
                        className={
                            !isExpanded
                                ? "primary-card-description"
                                : "primary-card-description primary-card-description-expanded"
                        }
                    >
                        {props.timeEntry.description}
                    </div>
                    <div className={"primary-card-content-action-panel"}>
                        <button
                            className={"primary-card-edit-btn"}
                            onClick={() => {
                                props.onEdit();
                            }}
                        >
                            <FaEdit />
                        </button>
                        <button className={"primary-card-delete-btn"} onClick={() => setDeleteConfirmationOpen(true)}>
                            <FaTrash />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
