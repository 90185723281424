import React, { FC } from "react";
import "./HomeView.css";
import DiadrawLogo from "../../../icons/DiadrawLogo.svg";

export const HomeView: FC = () => {
    return (
        <div className="homeview">
            <h1 className="home-label">📝Welcome to Diasheets!📝</h1>
            <p className="home-description">
                Designed to help
                <br />
                Developed by DTeam
            </p>
            <img className="home-logo" src={DiadrawLogo} alt="Diadraw Logo" />
        </div>
    );
};
