import { Form, FormInstance } from "antd";
import { useEffect, useMemo } from "react";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppVM } from "../../../AppVM";
import { TimeEntryType } from "../../../data/TimeEntryType";
import { addTimeEntryType, editTimeEntryType, TimeEntryTypeRecord } from "../../../api/timeEntryTypeApi";

interface TimeEntryTypeFormVM {
    timeEntryType: TimeEntryType;
    addTimeEntryTypeMutation: UseMutationResult<void, unknown, TimeEntryTypeRecord, unknown>;
    editTimeEntryTypeMutation: UseMutationResult<void, unknown, TimeEntryTypeRecord, unknown>;
    isEditing: boolean;
    form: FormInstance<any>;
    navigateToTimeEntryTypes: () => void;
}

export interface TimeEntryTypeFormValue {
    name: string;
    description: string;
    color: string;
}

export const useTimeEntryTypeFormVM = (): TimeEntryTypeFormVM => {
    const location = useLocation();
    const navigate = useNavigate();
    const { routes } = useAppVM();
    const [form] = Form.useForm();

    const queryClient = useQueryClient();

    const timeEntryType = useMemo(() => {
        return location.state as TimeEntryType;
    }, [location.state]);

    const addTimeEntryTypeMutation = useMutation(addTimeEntryType, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["TimeEntryTypes"]);
        },
    });

    const editTimeEntryTypeMutation = useMutation(editTimeEntryType, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["TimeEntryTypes"]);
        },
    });

    const isEditing = useMemo(() => {
        return !!location.state;
    }, [location.state]);

    useEffect(() => {
        if (timeEntryType) {
            form.setFieldsValue({
                name: timeEntryType.name,
                description: timeEntryType.description,
                color: timeEntryType.color,
            });
        }
    }, [timeEntryType]);

    const navigateToTimeEntryTypes = () => {
        navigate(routes.timeEntryTypes);
    };

    return {
        timeEntryType,
        addTimeEntryTypeMutation,
        editTimeEntryTypeMutation,
        isEditing,
        form,
        navigateToTimeEntryTypes,
    };
};
