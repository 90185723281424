import React, { useState } from "react";
import { NavigationButton } from "../../NavigationButton/View/NavigationButton";
import "./NavigationStyle.css";
import DiaLogo from "../../../icons/DiadrawLogo.svg";
import { useNavigationVM } from "../ViewModel/NavigationVM";
import { FaLock, FaLockOpen, FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";

interface NavigationProps {
    setMainContainerClassName: (className: string) => void;
}

export const Navigation = (props: NavigationProps): JSX.Element => {
    const {
        setIsNavbarExpanded,
        isNavbarExpanded,
        buttonsState,
        navigation,
        navigationClassName,
        navigationClassNames,
        setNavigationClassName,
        mainContainerClassNames,
        onNavigationButtonClick,
        user,
    } = useNavigationVM();

    const [isNavbarLocked, setIsNavbarLocked] = useState(false);

    return (
        <div
            className={navigationClassName}
            onMouseEnter={() => {
                if (navigationClassName !== navigationClassNames.navContainerLocked) {
                    setIsNavbarExpanded(true);
                }
            }}
            onMouseLeave={() => {
                if (navigationClassName !== navigationClassNames.navContainerExpandedLocked) {
                    setIsNavbarExpanded(false);
                }
            }}
        >
            <div>
                <img className={"nav-logo"} src={DiaLogo} />
                {user && (
                    <div className={"nav-buttons-container"}>
                        {buttonsState.map((item, index) => {
                            return (
                                <NavigationButton
                                    key={index}
                                    label={item.label}
                                    isClicked={item.isClicked}
                                    expanded={isNavbarExpanded}
                                    onClick={() => {
                                        onNavigationButtonClick(index);
                                        navigation(item.route);
                                    }}
                                >
                                    {<item.icon />}
                                </NavigationButton>
                            );
                        })}
                    </div>
                )}
            </div>
            {user && (
                <div className={"nav-lock-section"}>
                    {isNavbarLocked && (
                        <button
                            className={"nav-lock-btn"}
                            onClick={() => {
                                if (isNavbarExpanded) {
                                    setIsNavbarExpanded(false);
                                    props.setMainContainerClassName(mainContainerClassNames.mainContainer);
                                    setNavigationClassName(navigationClassNames.navContainerLocked);
                                } else {
                                    setIsNavbarExpanded(true);
                                    props.setMainContainerClassName(mainContainerClassNames.mainContainerExpanded);
                                    setNavigationClassName(navigationClassNames.navContainerExpandedLocked);
                                }
                            }}
                        >
                            {isNavbarLocked && isNavbarExpanded ? <FaArrowAltCircleLeft /> : <FaArrowAltCircleRight />}
                        </button>
                    )}
                    <button
                        className={"nav-lock-btn"}
                        onClick={() => {
                            if (isNavbarLocked) {
                                setNavigationClassName(navigationClassNames.navContainer);
                                props.setMainContainerClassName(mainContainerClassNames.mainContainer);
                            } else {
                                setNavigationClassName(navigationClassNames.navContainerExpandedLocked);
                                props.setMainContainerClassName(mainContainerClassNames.mainContainerExpanded);
                                setIsNavbarExpanded(true);
                            }
                            setIsNavbarLocked(!isNavbarLocked);
                        }}
                    >
                        {isNavbarLocked ? <FaLockOpen /> : <FaLock />}
                    </button>
                </div>
            )}
        </div>
    );
};
