import { ProjectRecord } from "../api/projectApi";

export class Project {
    id: number;
    name: string;
    description: string;
    statusId: number;
    startDateTimeStamp: string;
    endDateTimeStamp: string;
    isArchived: boolean;

    constructor(project: ProjectRecord) {
        this.id = project.id;
        this.name = project.name;
        this.description = project.description;
        this.statusId = project.status_id;
        this.startDateTimeStamp = project.start_date;
        this.endDateTimeStamp = project.end_date;
        this.isArchived = project.is_archived;
    }
}
