import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "react-query";
import {
    TimeEntryRecord,
    getTimeEntries,
    editTimeEntry,
    deleteTimeEntry,
    addTimeEntry,
    TimeEntryRecordsData,
} from "../../../api/timeEntryApi";
import { TimeEntry } from "../../../data/TimeEntry";
import { Dispatch, useMemo, useState } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { getTimeEntryTypes, TimeEntryTypeRecord } from "../../../api/timeEntryTypeApi";
import { TimeEntryType } from "../../../data/TimeEntryType";
import { defaultTimeEntryTypeColors, getTimeEntryTypeColorForTimeEntry } from "../../../util/timeEntryTypeColors";
import { DurationData, useTimeEntriesDuration } from "../../../util/hooks/useTimeEntriesDuration";
import { FilterCriteria, useFilterTimeEntries } from "../../../util/hooks/useFilterTimeEntries";
import { PaginationState, usePagination } from "../../../util/hooks/usePagination";
import { User } from "../../../api/userApi";
import { convertMomentToDateString } from "../../../util/momentDateUtil";

interface TimesheetVM {
    error: null | Error;
    loading: boolean;
    isFetching: boolean;
    timeEntries: TimeEntry[];
    addTimeEntryMutation: UseMutationResult<void, unknown, TimeEntryRecord, unknown>;
    editTimeEntryMutation: UseMutationResult<void, unknown, TimeEntryRecord, unknown>;
    deleteTimeEntryMutation: UseMutationResult<void, unknown, number, unknown>;
    getTimeEntryTypeColor: (timeEntry: TimeEntry) => string;
    durationData: DurationData;
    filterTimeEntries: (criteria: FilterCriteria) => void;
    paginationState: PaginationState;
    handlePaginationChange: (value: number) => void;
    resetPaginationState: () => void;
    navigate: NavigateFunction;
    timeEntriesTotal: number;
    setTimesheetFilters: Dispatch<React.SetStateAction<TimesheetFilters>>;
    timesheetFilters: TimesheetFilters;
}

export interface TimesheetFilters {
    project: string | undefined;
    type: string | undefined;
    dateRange: moment.Moment[] | undefined;
    pageSize: number;
    sortOrder: "asc" | "desc";
}

interface TimesheetVMProps {
    user: User | null;
    page: number;
}

export function useTimesheetVM(props: TimesheetVMProps): TimesheetVM {
    const [timesheetFilters, setTimesheetFilters] = useState<TimesheetFilters>({
        project: undefined,
        type: undefined,
        dateRange: undefined,
        pageSize: 30,
        sortOrder: "desc",
    });

    const timeEntryQuery: UseQueryResult<TimeEntryRecordsData, Error> = useQuery(
        ["TimeEntryList", props.page, timesheetFilters],
        () => {
            let start_date;
            let end_date;
            if (timesheetFilters.dateRange !== undefined) {
                start_date = convertMomentToDateString(timesheetFilters.dateRange[0]);
                end_date = convertMomentToDateString(timesheetFilters.dateRange[1]);
            }

            return getTimeEntries(
                props.user?.email,
                props.page,
                timesheetFilters.pageSize,
                timesheetFilters.project,
                timesheetFilters.type,
                start_date,
                end_date,
                timesheetFilters.sortOrder
            );
        },
        {
            enabled: !!props.user, // Skip the query if user is null or undefined
        }
    );

    const queryClient = useQueryClient();

    const addTimeEntryMutation = useMutation(addTimeEntry, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["TimeEntryList"]);
        },
    });

    const editTimeEntryMutation = useMutation(editTimeEntry, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["TimeEntryList"]);
        },
    });
    const deleteTimeEntryMutation = useMutation(deleteTimeEntry, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["TimeEntryList"]);
        },
    });

    const timeEntries: TimeEntry[] = useMemo<TimeEntry[]>(() => {
        if (timeEntryQuery.isFetched && timeEntryQuery.isSuccess) {
            return timeEntryQuery.data.data.map((record: TimeEntryRecord) => {
                return new TimeEntry(record);
            });
        }
        return [];
    }, [timeEntryQuery]);

    const timeEntriesTotal: number = useMemo<number>(() => {
        if (timeEntryQuery.isFetched && timeEntryQuery.isSuccess) {
            return timeEntryQuery.data.meta.total;
        }
        return 0;
    }, [timeEntryQuery]);

    const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>({});

    const filterTimeEntries = (criteria: FilterCriteria) => {
        setFilterCriteria(criteria);
    };

    const { filteredTimeEntries } = useFilterTimeEntries(timeEntries, filterCriteria);

    const navigate = useNavigate();

    const timeEntryTypesQuery: UseQueryResult<TimeEntryTypeRecord[], Error> = useQuery(
        "TimeEntryTypes",
        getTimeEntryTypes
    );

    const timeEntryTypes = useMemo<TimeEntryType[]>(() => {
        if (timeEntryTypesQuery.isFetched && timeEntryTypesQuery.isSuccess) {
            return timeEntryTypesQuery.data.map((timeEntryType: TimeEntryTypeRecord, index) => {
                return new TimeEntryType(timeEntryType, defaultTimeEntryTypeColors[index]);
            });
        }
        return [];
    }, [timeEntryTypesQuery]);

    // The data for total time entries duration and each type duration and color
    // Needed in TotalCard
    const { durationData } = useTimeEntriesDuration(filteredTimeEntries, timeEntryTypes);

    const getTimeEntryTypeColor = (timeEntry: TimeEntry) => {
        return getTimeEntryTypeColorForTimeEntry(timeEntry, timeEntryTypes);
    };

    const { paginationState, resetPaginationState, handlePaginationChange } = usePagination();

    return {
        error: timeEntryQuery.error,
        loading: timeEntryQuery.isLoading,
        timeEntries: filteredTimeEntries,
        isFetching: timeEntryQuery.isFetching,
        addTimeEntryMutation,
        editTimeEntryMutation,
        deleteTimeEntryMutation,
        getTimeEntryTypeColor,
        durationData,
        filterTimeEntries,
        paginationState,
        handlePaginationChange,
        resetPaginationState,
        navigate,
        timeEntriesTotal,
        setTimesheetFilters,
        timesheetFilters,
    };
}
