import React, { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authCallback, AuthRecord } from "../../api/authApi";
import { UserContextType, UserContext } from "../../context/UserContextProvider";
import { useAppVM } from "../../AppVM";
import { AxiosError } from "axios";

const SignInCallback = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { setUser } = useContext(UserContext) as UserContextType;
    const { routes } = useAppVM();

    useEffect(() => {
        authCallback(location.search)
            .then((data: AuthRecord) => {
                setUser(data.user);
                localStorage.setItem("access_token", `Bearer ${data.access_token}`);
                navigate("/");
            })
            .catch((error: AxiosError) => {
                if (error.response && error.response.status === 403) {
                    navigate(routes.signIn, { state: { message: "You have been deactivated!" } });
                }
            });
    }, []);

    return <div>Loading</div>;
};

export default SignInCallback;
