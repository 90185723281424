import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "react-query";
import {
    TimeEntryRecord,
    getTimeEntries,
    editTimeEntry,
    deleteTimeEntry,
    addTimeEntry,
    TimeEntryRecordsData,
} from "../../../api/timeEntryApi";
import { TimeEntry } from "../../../data/TimeEntry";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { getTimeEntryTypes, TimeEntryTypeRecord } from "../../../api/timeEntryTypeApi";
import { TimeEntryType } from "../../../data/TimeEntryType";
import { defaultTimeEntryTypeColors, getTimeEntryTypeColorForTimeEntry } from "../../../util/timeEntryTypeColors";
import { DurationData, useTimeEntriesDuration } from "../../../util/hooks/useTimeEntriesDuration";
import { FilterCriteria, useFilterTimeEntries } from "../../../util/hooks/useFilterTimeEntries";
import { PaginationState, usePagination } from "../../../util/hooks/usePagination";
import { User } from "../../../api/userApi";
import { convertMomentToDateString } from "../../../util/momentDateUtil";
import { ReportsFilters, useReportsContext } from "../../../context/ReportsContextProvider";
import moment, { Moment } from "moment";

interface ReportsVM {
    error: null | Error;
    loading: boolean;
    isFetching: boolean;
    timeEntries: TimeEntry[];
    addTimeEntryMutation: UseMutationResult<void, unknown, TimeEntryRecord, unknown>;
    editTimeEntryMutation: UseMutationResult<void, unknown, TimeEntryRecord, unknown>;
    deleteTimeEntryMutation: UseMutationResult<void, unknown, number, unknown>;
    getTimeEntryTypeColor: (timeEntry: TimeEntry) => string;
    durationData: DurationData;
    filterTimeEntries: (criteria: FilterCriteria) => void;
    paginationState: PaginationState;
    handlePaginationChange: (value: number) => void;
    resetPaginationState: () => void;
    navigate: NavigateFunction;
    timeEntriesTotal: number;
}

interface ReportsVMProps {
    user: User | null;
    page: number;
}

export function useReportsVM(props: ReportsVMProps): ReportsVM {
    const { filters, setFilters } = useReportsContext();

    useEffect(() => {
        const savedFilters: string | null = localStorage.getItem("reportFilters");

        if (savedFilters !== null && savedFilters !== undefined) {
            const parsedFilters: ReportsFilters = JSON.parse(savedFilters) as ReportsFilters;

            if (
                parsedFilters.dateRange !== undefined &&
                parsedFilters.dateRange[0] !== undefined &&
                parsedFilters.dateRange[1] !== undefined
            ) {
                const date1Converted: Moment = moment(parsedFilters.dateRange[0].toString());
                const date2Converted: Moment = moment(parsedFilters.dateRange[1].toString());
                parsedFilters.dateRange = [date1Converted, date2Converted];
            }

            setFilters(parsedFilters);
        }
    }, []);

    const timeEntryQuery: UseQueryResult<TimeEntryRecordsData, Error> = useQuery(
        ["TimeEntryReports", props.page, filters],
        () => {
            let start_date;
            let end_date;

            if (filters.dateRange !== undefined) {
                start_date = convertMomentToDateString(filters.dateRange[0]);
                end_date = convertMomentToDateString(filters.dateRange[1]);
            }

            return getTimeEntries(
                filters.username,
                props.page,
                filters.pageSize,
                filters.project,
                filters.type,
                start_date,
                end_date,
                filters.sortOrder
            );
        }
    );

    const queryClient = useQueryClient();

    const addTimeEntryMutation = useMutation(addTimeEntry, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["TimeEntryReports"]);
        },
    });

    const editTimeEntryMutation = useMutation(editTimeEntry, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["TimeEntryReports"]);
        },
    });
    const deleteTimeEntryMutation = useMutation(deleteTimeEntry, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["TimeEntryReports"]);
        },
    });

    const timeEntries: TimeEntry[] = useMemo<TimeEntry[]>(() => {
        if (timeEntryQuery.isFetched && timeEntryQuery.isSuccess) {
            return timeEntryQuery.data.data.map((record: TimeEntryRecord) => {
                return new TimeEntry(record);
            });
        }
        return [];
    }, [timeEntryQuery]);

    const timeEntriesTotal: number = useMemo<number>(() => {
        if (timeEntryQuery.isFetched && timeEntryQuery.isSuccess) {
            return timeEntryQuery.data.meta.total;
        }
        return 0;
    }, [timeEntryQuery]);

    const [filterCriteria, setFilterCriteria] = useState<FilterCriteria>({});

    const filterTimeEntries = (criteria: FilterCriteria) => {
        setFilterCriteria(criteria);
    };

    const { filteredTimeEntries } = useFilterTimeEntries(timeEntries, filterCriteria);

    const navigate = useNavigate();

    const timeEntryTypesQuery: UseQueryResult<TimeEntryTypeRecord[], Error> = useQuery(
        "TimeEntryTypes",
        getTimeEntryTypes
    );

    const timeEntryTypes = useMemo<TimeEntryType[]>(() => {
        if (timeEntryTypesQuery.isFetched && timeEntryTypesQuery.isSuccess) {
            return timeEntryTypesQuery.data.map((timeEntryType: TimeEntryTypeRecord, index) => {
                return new TimeEntryType(timeEntryType, defaultTimeEntryTypeColors[index]);
            });
        }
        return [];
    }, [timeEntryTypesQuery]);

    // The data for total time entries duration and each type duration and color
    // Needed in TotalCard
    const { durationData } = useTimeEntriesDuration(filteredTimeEntries, timeEntryTypes);

    const getTimeEntryTypeColor = (timeEntry: TimeEntry) => {
        return getTimeEntryTypeColorForTimeEntry(timeEntry, timeEntryTypes);
    };

    const { paginationState, resetPaginationState, handlePaginationChange } = usePagination();

    return {
        error: timeEntryQuery.error,
        loading: timeEntryQuery.isLoading,
        timeEntries: filteredTimeEntries,
        isFetching: timeEntryQuery.isFetching,
        addTimeEntryMutation,
        editTimeEntryMutation,
        deleteTimeEntryMutation,
        getTimeEntryTypeColor,
        durationData,
        filterTimeEntries,
        paginationState,
        handlePaginationChange,
        resetPaginationState,
        navigate,
        timeEntriesTotal,
    };
}
