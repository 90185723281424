import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "react-query";
import { useNavigate } from "react-router-dom";
import { deleteTimeEntryType, getTimeEntryTypes, TimeEntryTypeRecord } from "../../../api/timeEntryTypeApi";
import { RoutePaths, useAppVM } from "../../../AppVM";
import { useMemo } from "react";
import { TimeEntryType } from "../../../data/TimeEntryType";
import { defaultTimeEntryTypeColors } from "../../../util/timeEntryTypeColors";

interface TimeEntryTypesVM {
    error: null | Error;
    loading: boolean;
    timeEntryTypes: TimeEntryType[];
    deleteTimeEntryTypeMutation: UseMutationResult<void, unknown, number, unknown>;
    navigateToCreateTimeEntryType: () => void;
    navigateToEditTimeEntryType: (timeEntryType: TimeEntryType) => void;
    navigateToSettings: () => void;
    routes: RoutePaths;
}

export function useTimeEntryTypesVM(): TimeEntryTypesVM {
    const timeEntryTypesQuery: UseQueryResult<TimeEntryTypeRecord[], Error> = useQuery(
        "TimeEntryTypes",
        getTimeEntryTypes
    );
    const navigate = useNavigate();
    const { routes } = useAppVM();

    const queryClient = useQueryClient();

    const timeEntryTypes = useMemo<TimeEntryType[]>(() => {
        if (timeEntryTypesQuery.isFetched && timeEntryTypesQuery.isSuccess) {
            return timeEntryTypesQuery.data.map((timeEntryType: TimeEntryTypeRecord, index) => {
                return new TimeEntryType(timeEntryType, defaultTimeEntryTypeColors[index]);
            });
        }
        return [];
    }, [timeEntryTypesQuery]);

    const deleteTimeEntryTypeMutation = useMutation(deleteTimeEntryType, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["TimeEntryTypes"]);
        },
    });

    const navigateToCreateTimeEntryType = () => {
        navigate(`${routes.timeEntryTypes}/create`);
    };

    const navigateToEditTimeEntryType = (timeEntryType: TimeEntryType) => {
        navigate(`${routes.timeEntryTypes}/edit/${timeEntryType.id}`, {
            state: {
                ...timeEntryType,
            },
        });
    };

    const navigateToSettings = () => {
        navigate(routes.settings);
    };

    return {
        error: timeEntryTypesQuery.error,
        loading: timeEntryTypesQuery.isLoading,
        timeEntryTypes: timeEntryTypes || [],
        deleteTimeEntryTypeMutation,
        navigateToCreateTimeEntryType,
        navigateToEditTimeEntryType,
        navigateToSettings,
        routes,
    };
}
