import React from "react";
import { TeamMemberFormValue, useTeamMemberFormVM } from "../ViewModel/TeamMemberFormVM";
import { Button, Form, Input, Select } from "antd";
import { FormHeader } from "../../FormHeader/View/FormHeader";

export const TeamMemberForm = (): JSX.Element => {
    const { Option } = Select;
    const { addTeamMemberMutation, form, navigateBack, users, teams } = useTeamMemberFormVM();

    return (
        <>
            <FormHeader label={"Add users to project"} onReturn={navigateBack} />
            <div className={"form-container"}>
                <div className={"form-centered"}>
                    <Form
                        name="projectForm"
                        form={form}
                        onFinish={() => {
                            const formFieldsValue = form.getFieldsValue() as TeamMemberFormValue;

                            addTeamMemberMutation.mutate({
                                id: 0,
                                username: formFieldsValue.username,
                                team_id: formFieldsValue.team_id,
                                role: formFieldsValue.role,
                            });

                            navigateBack();
                        }}
                    >
                        <Form.Item name="username" rules={[{ required: true, message: "Please choose a user!" }]}>
                            <h3>Username</h3>
                            <Select
                                placeholder={"Username"}
                                size={"middle"}
                                onChange={(value: string) => {
                                    form.setFieldsValue({ username: value });
                                }}
                            >
                                {users.map((user) => {
                                    return (
                                        <Option key={user.id} value={user.email}>
                                            {user.email}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="team_id"
                            rules={[{ required: true, message: "Please choose a team for the user!" }]}
                        >
                            <h3>Team</h3>
                            <Select
                                placeholder={"Team"}
                                size={"middle"}
                                onChange={(value: number) => {
                                    form.setFieldsValue({ team_id: value });
                                }}
                            >
                                {teams.map((team) => {
                                    return (
                                        <Option key={team.id} value={team.id}>
                                            {team.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item name="role" rules={[{ required: true, message: "Please add a role for the user!" }]}>
                            <h3>Role</h3>
                            <Input
                                placeholder={"Role"}
                                size={"middle"}
                                onChange={(value) => {
                                    form.setFieldsValue({ role: value.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <div className={"inline-buttons-container"}>
                                <Button type={"primary"} htmlType={"submit"}>
                                    Save
                                </Button>
                                <Button onClick={navigateBack}>Cancel</Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    );
};
