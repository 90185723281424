import { UseMutationResult, useMutation, useQueryClient } from "react-query";
import { User, editUser } from "../../../api/userApi";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppVM } from "../../../AppVM";
import { Form, FormInstance } from "antd";

interface UserEditFormVM {
    editUserMutation: UseMutationResult<void, unknown, User, unknown>;
    form: FormInstance<any>;
    navigateToUsers: () => void;
    user: User;
}

export interface UserEditFormValue {
    deactivated: boolean;
    role_id: number;
}

export const useUserEditFormVM = (): UserEditFormVM => {
    const location = useLocation();
    const navigate = useNavigate();
    const { routes } = useAppVM();
    const [form] = Form.useForm();

    const queryClient = useQueryClient();

    const user = useMemo(() => {
        return location.state as User;
    }, [location.state]);

    const editUserMutation = useMutation(editUser, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["Users"]);
        },
    });

    const navigateToUsers = () => {
        navigate(routes.users, {});
    };

    useEffect(() => {
        if (user) {
            form.setFieldsValue({
                deactivated: user.deactivated,
                role_id: user.role_id,
            });
        }
    }, [user]);

    return {
        editUserMutation,
        form,
        navigateToUsers,
        user,
    };
};
