import React, { useState } from "react";

interface Expansion {
    isExpanded: boolean;
    setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useExpansion = (): Expansion => {
    const [isExpanded, setIsExpanded] = useState(false);

    return {
        isExpanded,
        setIsExpanded,
    };
};
