import React, { createContext, useContext, useState, ReactNode } from "react";
import moment from "moment";

export interface ReportsFilters {
    project: string | undefined;
    username: string | undefined;
    type: string | undefined;
    dateRange: moment.Moment[] | undefined;
    pageSize: number;
    sortOrder: "asc" | "desc";
}

interface ReportsContextType {
    filters: ReportsFilters;
    setFilters: React.Dispatch<React.SetStateAction<ReportsFilters>>;
}

const ReportsContext = createContext<ReportsContextType | undefined>(undefined);

export function useReportsContext() {
    const context = useContext(ReportsContext);
    if (!context) {
        throw new Error("useReportsContext must be used within a ReportsProvider");
    }
    return context;
}

interface ReportsContextProviderProps {
    children: ReactNode;
}

export function ReportsContextProvider({ children }: ReportsContextProviderProps) {
    const [filters, setFilters] = useState<ReportsFilters>({
        project: undefined,
        username: undefined,
        type: undefined,
        dateRange: undefined,
        pageSize: 30,
        sortOrder: "desc",
    });

    return <ReportsContext.Provider value={{ filters, setFilters }}>{children}</ReportsContext.Provider>;
}
