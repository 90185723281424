import axios, { AxiosRequestConfig } from "axios";

export const client = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });
// export const client = axios.create({ baseURL: "http://localhost:8000/api" });

client.interceptors.request.use(
    (request: AxiosRequestConfig) => {
        if (request.headers !== undefined) {
            request.headers.Authorization = `${localStorage.getItem("access_token") || ""}`;
        }
        return request;
    },
    (error) => {
        return Promise.reject(error);
    }
);
