import React from "react";
import SignInButton from "../../../components/SignInButton/View/SignInButton";
import "./LoginView.css";
import { useLoginViewVM } from "../ViewModel/LoginViewVM";

const LoginView: React.FC = () => {
    const { messageState } = useLoginViewVM();

    return (
        <div className="loginview">
            <h1>Login</h1>
            <div className={"header-divider"} />
            {messageState && <p>{messageState.message}</p>}
            <SignInButton />
        </div>
    );
};

export default LoginView;
