import React, { ReactNode } from "react";

import "./HeaderStyle.css";
import { useHeaderVM } from "../ViewModel/HeaderVM";

interface HeaderProps {
    children?: ReactNode;
}

export const Header = (props: HeaderProps): JSX.Element => {
    const { isScrolling } = useHeaderVM();

    return (
        <div className={!isScrolling ? "header-container" : "header-container header-container-scrolling"}>
            {props.children}
        </div>
    );
};
