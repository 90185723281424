import { UseMutationResult, useMutation, useQueryClient } from "react-query";
import { Team, addTeam, editTeam } from "../../../api/teamApi";
import { Project } from "../../../data/Project";
import React, { useEffect, useMemo } from "react";
import { Form, FormInstance } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

interface TeamFormVM {
    addTeamMutation: UseMutationResult<void, unknown, Team, unknown>;
    editTeamMutation: UseMutationResult<void, unknown, Team, unknown>;
    form: FormInstance<any>;
    navigateBack: () => void;
    navigationState: TeamFormVMState;
}

interface TeamFormVMState {
    project?: Project;
    team?: Team;
}

export interface TeamFormValue {
    id: number;
    project_id: number;
    name: string;
    description: string;
}

export const useTeamFormVM = (): TeamFormVM => {
    const location = useLocation();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const queryClient = useQueryClient();

    const navigationState = useMemo(() => {
        return location.state as TeamFormVMState;
    }, [location.state]);

    const addTeamMutation = useMutation(addTeam, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["Teams"]);
        },
    });

    const editTeamMutation = useMutation(editTeam, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["Teams"]);
        },
    });

    useEffect(() => {
        if (navigationState.team) {
            form.setFieldsValue({
                id: navigationState.team.id,
                project_id: navigationState.team.project_id,
                name: navigationState.team.name,
                description: navigationState.team.description,
            });
        } else if (navigationState.project) {
            form.setFieldsValue({
                project_id: navigationState.project.id,
            });
        }
    }, [navigationState]);

    const navigateBack = () => {
        navigate(-1);
    };

    return {
        addTeamMutation,
        editTeamMutation,
        form,
        navigateBack,
        navigationState,
    };
};
