import React from "react";

interface ScrollingBodyProps {
    header?: React.ReactNode;
    footer?: React.ReactNode;
    children: React.ReactNode;
}
export const ScrollingBody = (props: ScrollingBodyProps) => {
    return (
        <div className={"scrolling-body"}>
            {props.header}
            <div className={"scrolling-body-content"}>
                {props.children}
                <div style={{ height: "250px" }} />
            </div>

            <div className={"scrolling-body-footer"}>{props.footer}</div>
        </div>
    );
};
