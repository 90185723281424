import { client } from "./client";

export interface TeamMembership {
    id: number;
    username: string;
    team_id: number;
    role: string;
}

export interface TeamMembershipsData {
    data: TeamMembership[];
}

export async function getTeamMemberships(team_id: number): Promise<TeamMembership[]> {
    const requestBody = {
        team_id: team_id,
    };

    const response = await client.get<TeamMembershipsData>("/teamMembership", { params: requestBody });
    return response.data.data;
}

export async function getTeamMembershipById(id: number): Promise<TeamMembership> {
    const response = await client.get<TeamMembership>(`/teamMembership/${id}`);
    return response.data;
}

export async function addTeamMembership(teamMembership: TeamMembership): Promise<void> {
    await client.post<TeamMembership>(`/teamMembership`, teamMembership);
}

export async function editTeamMembership(teamMembership: TeamMembership): Promise<void> {
    await client.put<TeamMembership>(`/teamMembership/${teamMembership.id}`, teamMembership);
}

export async function deleteTeamMembership(id: number): Promise<void> {
    await client.delete<TeamMembership>(`/teamMembership/${id}`);
}
