import { client } from "./client";

export interface TimeEntryRecord {
    id: number;
    username: string;
    projectName: string;
    description: string;
    duration: number;
    startTime: number;
    endTime: number;
    type: string;
    createdBy: string;
    lastUpdatedBy: string;
}

export interface TimeEntryMeta {
    total: number;
}

export interface TimeEntryRecordsData {
    data: TimeEntryRecord[];
    meta: TimeEntryMeta;
}

export async function getTimeEntries(
    username: string | undefined,
    page: number,
    perPage: number,
    projectName: string | undefined,
    type: string | undefined,
    start_date: string | undefined,
    end_date: string | undefined,
    sort_order: "asc" | "desc"
): Promise<TimeEntryRecordsData> {
    const requestBody = {
        username: username,
        page: page,
        per_page: perPage,
        projectName: projectName,
        type: type,
        start_date: start_date,
        end_date: end_date,
        sort_order: sort_order,
    };

    const response = await client.get<TimeEntryRecordsData>("/timeEntry", { params: requestBody });
    return response.data;
}

export async function getTimeEntryById(id: number): Promise<TimeEntryRecord> {
    const response = await client.get<TimeEntryRecord>(`/timeEntry/${id}`);
    return response.data;
}

export async function addTimeEntry(timeEntry: TimeEntryRecord): Promise<void> {
    await client.post<TimeEntryRecord>(`/timeEntry`, timeEntry);
}

export async function editTimeEntry(timeEntry: TimeEntryRecord): Promise<void> {
    await client.put<TimeEntryRecord>(`/timeEntry/${timeEntry.id}`, timeEntry);
}

export async function deleteTimeEntry(id: number): Promise<void> {
    await client.delete<TimeEntryRecord>(`/timeEntry/${id}`);
}
