import { useState } from "react";

interface HeaderVM {
    isScrolling: boolean;
}

export const useHeaderVM = (): HeaderVM => {
    const [isScrolling, setIsScrolling] = useState(false);

    const handleScroll = () => {
        if (window.pageYOffset > 10) {
            if (!isScrolling) {
                setIsScrolling(true);
            }
        } else {
            if (isScrolling) {
                setIsScrolling(false);
            }
        }
    };

    window.addEventListener("scroll", handleScroll);

    return {
        isScrolling,
    };
};
