import React from "react";
import { FormHeader } from "../../../components/FormHeader/View/FormHeader";
import { Button, DatePicker, Form, Input, Select, Tooltip } from "antd";
import { FaCalendar } from "react-icons/fa";
import "./TimeEntryFormStyle.css";
import { TimeInput } from "../../../components/TimeInput/View/TimeInput";
import { TimeEntryFormValue, useTimeEntryFormVM } from "../ViewModel/TimeEntryFormVM";
import { convertDateStringToMoment, convertMomentToDateString } from "../../../util/momentDateUtil";
import { convertHoursToSeconds } from "../../../util/dateTimeUtil";
import moment from "moment";

export const TimeEntryForm = (): JSX.Element => {
    const { Option } = Select;
    const { TextArea } = Input;
    const {
        timeEntry,
        addTimeEntryMutation,
        editTimeEntryMutation,
        isEditing,
        projects,
        types,
        form,
        navigateBack,
        user,
    } = useTimeEntryFormVM();

    return (
        <>
            <FormHeader label={isEditing ? "Edit" : "Create"} onReturn={navigateBack} />
            <div className={"form-container"}>
                <div className={"form-centered"}>
                    <Form
                        name="timeEntryForm"
                        form={form}
                        initialValues={{
                            date: isEditing ? convertDateStringToMoment(timeEntry.startDateTimeStamp) : moment(),
                            projectName: isEditing ? timeEntry.projectName : projects?.at(0)?.name,
                            type: isEditing ? timeEntry.type : types?.at(0)?.name,
                            duration: timeEntry ? timeEntry.duration : 8,
                        }}
                        onFinish={() => {
                            const formFieldsValue = form.getFieldsValue() as TimeEntryFormValue;
                            if (isEditing) {
                                editTimeEntryMutation.mutate({
                                    ...timeEntry,
                                    projectName: formFieldsValue.projectName,
                                    type: formFieldsValue.type,
                                    description: formFieldsValue.description,
                                    duration: convertHoursToSeconds(formFieldsValue.duration),
                                    startTime: formFieldsValue.date,
                                    endTime: formFieldsValue.date,
                                });
                            } else {
                                if (!user || !user.email) {
                                    navigateBack();
                                    return;
                                }
                                // username, lastUpdatedBy and createdBy are required for a successful request.
                                addTimeEntryMutation.mutate({
                                    id: 0,
                                    username: user?.email,
                                    lastUpdatedBy: user?.email,
                                    createdBy: user?.email,
                                    projectName: formFieldsValue.projectName,
                                    type: formFieldsValue.type,
                                    description: formFieldsValue.description,
                                    duration: convertHoursToSeconds(formFieldsValue.duration),
                                    startTime: formFieldsValue.date,
                                    endTime: formFieldsValue.date,
                                });
                            }
                            navigateBack();
                        }}
                    >
                        <Form.Item name="date" rules={[{ required: true, message: "Please input your workday date!" }]}>
                            <h3>Date</h3>

                            <DatePicker
                                className={"date-picker"}
                                suffixIcon={<FaCalendar />}
                                defaultValue={
                                    isEditing ? convertDateStringToMoment(timeEntry.startDateTimeStamp) : moment()
                                }
                                onChange={(momentDate) => {
                                    if (momentDate) {
                                        const date = convertMomentToDateString(momentDate);
                                        form.setFieldsValue({ date: date });
                                    }
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="projectName"
                            rules={[{ required: true, message: "Please input your work project!" }]}
                        >
                            <h3>Project</h3>
                            <Select
                                placeholder={"Project"}
                                size={"middle"}
                                defaultValue={isEditing ? timeEntry.projectName : projects?.at(0)?.name}
                                onChange={(value) => {
                                    form.setFieldsValue({ projectName: value });
                                }}
                            >
                                {projects?.map((project) => {
                                    return (
                                        <Option key={project.id} value={project.name}>
                                            {project.name}
                                        </Option>
                                    );
                                })}
                                <Option key={"Research"} value={"Research"}>
                                    {"Research"}
                                </Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="type" rules={[{ required: true, message: "Please input your work type!" }]}>
                            <h3>Type</h3>
                            <Select
                                placeholder={"Type"}
                                defaultValue={isEditing ? timeEntry.type : types?.at(0)?.name}
                                onChange={(value) => {
                                    form.setFieldsValue({ type: value });
                                }}
                            >
                                {types?.map((type) => {
                                    return (
                                        <Option key={type.id} value={type.name}>
                                            <Tooltip placement="right" title={type.description}>
                                                <span>{type.name}</span>
                                            </Tooltip>
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="duration"
                            rules={[{ required: true, message: "Please input your work duration!" }]}
                        >
                            <TimeInput
                                value={timeEntry ? timeEntry.duration : null}
                                onChange={(value) => {
                                    form.setFieldsValue({ duration: value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            rules={[{ required: true, message: "Please input your work description!" }]}
                        >
                            <h3>Description</h3>
                            <TextArea
                                rows={5}
                                placeholder={"Description"}
                                defaultValue={isEditing ? timeEntry.description : ""}
                                onChange={(value) => {
                                    form.setFieldsValue({ description: value.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <div className={"inline-buttons-container"}>
                                <Button type={"primary"} htmlType={"submit"}>
                                    Save
                                </Button>
                                <Button onClick={navigateBack}>Cancel</Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    );
};
