import { client } from "./client";

export interface Status {
    id: number;
    name: string;
    description: string;
}

export interface StatusByIdResponse {
    data: Status;
}

interface StatusesData {
    data: Status[];
}

export async function getStatuses(): Promise<Status[]> {
    const response = await client.get<StatusesData>("/status");
    return response.data.data;
}

export async function getStatusById(id: number): Promise<Status> {
    const response = await client.get<StatusByIdResponse>(`/status/${id}`);
    return response.data.data;
}

export async function addStatus(status: Status): Promise<void> {
    await client.post<Status>(`/status`, status);
}

export async function editStatus(id: number, status: Status): Promise<void> {
    await client.put<Status>(`/status/${id}`, status);
}

export async function deleteStatus(id: number): Promise<void> {
    await client.delete<Status>(`/status/${id}`);
}
