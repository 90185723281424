import React, { useState } from "react";
import { FaEdit, FaTrash, FaAngleUp, FaAngleDown, FaInfoCircle } from "react-icons/fa";
import "../../TimeEntryCard/View/TimeEntryCardStyle.css";
import { useExpansion } from "../../../util/hooks/useExpansion";
import { Project } from "../../../data/Project";
import { useProjectCardVM } from "../ViewModel/ProjectCardVM";
import { DeleteConfirmationModal } from "../../DeleteConfirmationModal/View/DeleteConfirmationModal";

interface ProjectCardProps {
    project: Project;
    onDelete: (id: number) => void;
    onEdit: () => void;
    onInfo: () => void;
}

export const ProjectCard = (props: ProjectCardProps): JSX.Element => {
    const { isExpanded, setIsExpanded } = useExpansion();
    const { statusName } = useProjectCardVM(props.project.statusId);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);

    return (
        <>
            {deleteConfirmationOpen && (
                <DeleteConfirmationModal
                    text={`Are you sure you want to delete ${props.project.name}?`}
                    onAccept={() => {
                        props.onDelete(props.project.id);
                        setDeleteConfirmationOpen(false);
                    }}
                    onDecline={() => setDeleteConfirmationOpen(false)}
                />
            )}
            <div className={"primary-card-container"}>
                <div className={"primary-card-header nandor"} onClick={() => setIsExpanded(!isExpanded)}>
                    {props.project.name}
                    {isExpanded ? <FaAngleUp /> : <FaAngleDown />}
                </div>
                <div className={"primary-card-content-project"}>
                    <div className={"primary-card-content-item-centered"}>{statusName}</div>
                    <div
                        className={
                            !isExpanded
                                ? "primary-card-description-project"
                                : "primary-card-description-project primary-card-description-project-expanded"
                        }
                    >
                        {props.project.description}
                    </div>
                    <div className={"primary-card-content-action-panel"}>
                        <button
                            className={"primary-card-info-btn"}
                            onClick={() => {
                                props.onInfo();
                            }}
                        >
                            <FaInfoCircle />
                        </button>
                        <button
                            className={"primary-card-edit-btn"}
                            onClick={() => {
                                props.onEdit();
                            }}
                        >
                            <FaEdit />
                        </button>
                        <button className={"primary-card-delete-btn"} onClick={() => setDeleteConfirmationOpen(true)}>
                            <FaTrash />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
