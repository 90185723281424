import React, { useState } from "react";
import { useTeamCardVM } from "../ViewModel/TeamCardVM";
import { TeamMemberCard } from "../../TeamMemberCard/VIew/TeamMemberCard";
import { Team } from "../../../api/teamApi";
import { TeamMembership } from "../../../api/teamMembershipsApi";
import { FaEdit, FaTrash } from "react-icons/fa";
import { DeleteConfirmationModal } from "../../DeleteConfirmationModal/View/DeleteConfirmationModal";

interface TeamCardProps {
    team: Team;
    onDelete: (id: number) => void;
    onEdit: (teamToEdit: Team) => void;
}

export const TeamCard = (props: TeamCardProps) => {
    const { teamMemberships, deleteTeamMembershipMutation } = useTeamCardVM({ team: props.team });
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);

    return (
        <>
            {deleteConfirmationOpen && (
                <DeleteConfirmationModal
                    text={`Are you sure you want to delete ${props.team.name}?`}
                    onAccept={() => {
                        props.onDelete(props.team.id);
                        setDeleteConfirmationOpen(false);
                    }}
                    onDecline={() => setDeleteConfirmationOpen(false)}
                />
            )}
            <div className={"primary-card-container"}>
                <div className={"primary-card-header neptune"}>
                    <h3>{props.team.name}</h3>
                </div>
                <div className="primary-card-content-team">
                    <h4>{[props.team.description]}</h4>
                    {teamMemberships.length === 0 ? (
                        <p>Team is empty</p>
                    ) : (
                        teamMemberships.map((membership: TeamMembership) => (
                            <TeamMemberCard
                                key={membership.id}
                                teamMembership={membership}
                                onDelete={() => deleteTeamMembershipMutation.mutate(membership.id)}
                                teamName={props.team.name}
                            />
                        ))
                    )}
                    <div className="team-card-button-container">
                        <button className={"ant-btn ant-btn-danger"} onClick={() => setDeleteConfirmationOpen(true)}>
                            <FaTrash />
                            Delete Team
                        </button>
                        <button className={"ant-btn ant-btn-primary"} onClick={() => props.onEdit(props.team)}>
                            <FaEdit />
                            Edit Team
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
