import { useNavigate } from "react-router-dom";
import { useAppVM } from "../../../AppVM";

interface SettingsVM {
    navigateToUsers: () => void;
    navigateToProjects: () => void;
    navigateToTimeEntryTypes: () => void;
}

export const useSettingsVM = (): SettingsVM => {
    const navigate = useNavigate();

    const { routes } = useAppVM();

    const navigateToUsers = () => {
        navigate(`${routes.settings}/users`);
    };

    const navigateToProjects = () => {
        navigate(`${routes.settings}/projects`);
    };

    const navigateToTimeEntryTypes = () => {
        navigate(`${routes.settings}/timeEntryTypes`);
    };

    return {
        navigateToUsers,
        navigateToProjects,
        navigateToTimeEntryTypes,
    };
};
