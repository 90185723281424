import { TimeEntryTypeRecord } from "../api/timeEntryTypeApi";

export class TimeEntryType {
    id: number;
    name: string;
    description: string;
    color: string;

    constructor(timeEntryType: TimeEntryTypeRecord, color: string) {
        this.id = timeEntryType.id;
        this.name = timeEntryType.name;
        this.description = timeEntryType.description;
        this.color = color;
    }
}
