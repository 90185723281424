import React, { useState } from "react";
import { User, roleMapping } from "../../../api/userApi";
import { useExpansion } from "../../../util/hooks/useExpansion";
import { FaEdit, FaTrash, FaAngleUp, FaAngleDown } from "react-icons/fa";
import { DeleteConfirmationModal } from "../../DeleteConfirmationModal/View/DeleteConfirmationModal";

interface UserCardProps {
    User: User;
    onDelete: (id: number) => void;
    onEdit: () => void;
    onInfo: () => void;
}

export const UserCard = (props: UserCardProps): JSX.Element => {
    const { isExpanded, setIsExpanded } = useExpansion();
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);

    return (
        <>
            {deleteConfirmationOpen && (
                <DeleteConfirmationModal
                    text={`Are you sure you want to delete ${props.User.email}?`}
                    onAccept={() => {
                        props.onDelete(props.User.id);
                        setDeleteConfirmationOpen(false);
                    }}
                    onDecline={() => setDeleteConfirmationOpen(false)}
                />
            )}
            <div className={"primary-card-container-user-card"}>
                <div className={"primary-card-header nandor"} onClick={() => setIsExpanded(!isExpanded)}>
                    {props.User.name}
                    {isExpanded ? <FaAngleUp /> : <FaAngleDown />}
                </div>
                <div className={"primary-card-content-user"}>
                    <div className={"primary-card-description"}>{props.User.email}</div>
                    <div className={"primary-card-content"}>
                        <p>{roleMapping.get(props.User.role_id.toString())}</p>
                        <p>{`Active: ${props.User.deactivated ? "❌" : "✅"}`}</p>
                    </div>

                    <div className={"primary-card-content-action-panel"}>
                        {/* <button
                        className={"primary-card-info-btn"}
                        onClick={() => {
                            props.onInfo();
                        }}
                    >
                        <FaInfoCircle />
                    </button> */}
                        <button
                            className={"primary-card-edit-btn"}
                            onClick={() => {
                                props.onEdit();
                            }}
                        >
                            <FaEdit />
                        </button>
                        <button className={"primary-card-delete-btn"} onClick={() => setDeleteConfirmationOpen(true)}>
                            <FaTrash />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
