import { TimeEntryRecord } from "../api/timeEntryApi";
import { convertSecondsToHours, formatDate } from "../util/dateTimeUtil";

export class TimeEntry {
    id: number;
    username: string;
    projectName: string;
    description: string;
    duration: number;
    startDateTimeStamp: string;
    endDateTimeStamp: string;
    type: string;
    createdBy: string;
    lastUpdatedBy: string;

    constructor(timeEntryRecord: TimeEntryRecord) {
        this.id = timeEntryRecord.id;
        this.username = timeEntryRecord.username;
        this.projectName = timeEntryRecord.projectName;
        this.description = timeEntryRecord.description;
        this.duration = convertSecondsToHours(timeEntryRecord.duration);
        this.startDateTimeStamp = formatDate(new Date(timeEntryRecord.startTime * 1000));
        this.endDateTimeStamp = formatDate(new Date(timeEntryRecord.endTime * 1000));
        this.type = timeEntryRecord.type;
        this.createdBy = timeEntryRecord.createdBy;
        this.lastUpdatedBy = timeEntryRecord.lastUpdatedBy;
    }
}
