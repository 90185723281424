import React, { useEffect, useMemo } from "react";
import { Project } from "../../../data/Project";
import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";
import { TeamMembership, addTeamMembership } from "../../../api/teamMembershipsApi";
import { Form, FormInstance } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { User, getUsers } from "../../../api/userApi";
import { Team, getTeams } from "../../../api/teamApi";

interface TeamMemberFormVM {
    project: Project;
    form: FormInstance<any>;
    navigateBack: () => void;
    users: User[];
    teams: Team[];
    addTeamMemberMutation: UseMutationResult<void, unknown, TeamMembership, unknown>;
}

export interface TeamMemberFormValue {
    team_id: number;
    username: string;
    role: string;
}

export const useTeamMemberFormVM = (): TeamMemberFormVM => {
    const location = useLocation();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const queryClient = useQueryClient();

    const project = useMemo(() => {
        return location.state as Project;
    }, [location.state]);

    const addTeamMemberMutation = useMutation(addTeamMembership, {
        onSuccess: async () => {
            const queries = queryClient.getQueryCache().findAll();

            // Filter queries that contain the word "TeamMemberships"
            const queriesToInvalidate = queries.filter((query) => query.queryKey.includes("TeamMemberships"));

            // Invalidate the filtered queries
            await Promise.all(queriesToInvalidate.map((query) => queryClient.invalidateQueries(query.queryKey)));
        },
    });

    const usersQuery: UseQueryResult<User[], Error> = useQuery("Users", () => {
        return getUsers();
    });

    const users: User[] = useMemo<User[]>(() => {
        if (usersQuery.isFetched && usersQuery.isSuccess) {
            return usersQuery.data.map((user: User) => {
                return user;
            });
        }
        return [];
    }, [usersQuery]);

    useEffect(() => {
        if (project) {
            form.setFieldsValue({
                project_id: project.id,
            });
        }
    }, [project]);

    const navigateBack = () => {
        navigate(-1);
    };

    const TeamsQuery: UseQueryResult<Team[], Error> = useQuery("Teams", () => getTeams(project.id));

    const teams: Team[] = useMemo<Team[]>(() => {
        if (TeamsQuery.isFetched && TeamsQuery.isSuccess) {
            return TeamsQuery.data;
        }
        return [];
    }, [TeamsQuery]);

    return {
        project,
        form,
        navigateBack,
        users,
        teams,
        addTeamMemberMutation,
    };
};
