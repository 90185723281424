import React, { useMemo, useState } from "react";
import { User, deleteUser, getUsers } from "../../../api/userApi";
import { useNavigate } from "react-router-dom";
import { useAppVM } from "../../../AppVM";
import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from "react-query";

interface UsersVM {
    error: null | Error;
    loading: boolean;
    users: User[];
    deleteUserMutation: UseMutationResult<void, unknown, number, unknown>;
    navigateToEditUser: (user: User) => void;
    navigateToUserInfo: (user: User) => void;
    navigateToSettings: () => void;
    searchWord: string;
    setSearchWord: React.Dispatch<React.SetStateAction<string>>;
}

export function useUsersVM(): UsersVM {
    const [searchWord, setSearchWord] = useState<string>("");

    const usersQuery: UseQueryResult<User[], Error> = useQuery(["Users", searchWord], () => {
        return getUsers(searchWord);
    });

    const navigate = useNavigate();
    const { routes } = useAppVM();

    const queryClient = useQueryClient();

    const users: User[] = useMemo<User[]>(() => {
        if (usersQuery.isFetched && usersQuery.isSuccess) {
            return usersQuery.data;
        }
        return [];
    }, [usersQuery]);

    const deleteUserMutation = useMutation(deleteUser, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["Users"]);
        },
    });

    const navigateToEditUser = (user: User) => {
        navigate(`${routes.users}/edit/${user.id}`, {
            state: {
                ...user,
            },
        });
    };

    const navigateToSettings = () => {
        navigate(routes.settings);
    };

    const navigateToUserInfo = (user: User) => {
        navigate(`${routes.users}/${user.id}`, {
            state: {
                ...user,
            },
        });
    };

    return {
        error: usersQuery.error,
        loading: usersQuery.isLoading,
        users: users || [],
        deleteUserMutation,
        navigateToEditUser,
        navigateToUserInfo,
        navigateToSettings,
        searchWord,
        setSearchWord,
    };
}

export default UsersVM;
