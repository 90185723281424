import { Button, Checkbox, Form, Select } from "antd";
import { FormHeader } from "../../FormHeader/View/FormHeader";
import { UserEditFormValue, useUserEditFormVM } from "../ViewModel/UserEditFormVM";
import { roleMapping } from "../../../api/userApi";
import React from "react";

export const UserEditForm = (): JSX.Element => {
    const { user, editUserMutation, form, navigateToUsers } = useUserEditFormVM();
    const { Option } = Select;

    return (
        <>
            <FormHeader label={`Editing ${user.name}`} onReturn={navigateToUsers} />
            <div className={"form-container"}>
                <div className={"form-centered"}>
                    <Form
                        name="projectForm"
                        form={form}
                        onFinish={() => {
                            const formFieldsValue = form.getFieldsValue() as UserEditFormValue;
                            editUserMutation.mutate({
                                id: user.id,
                                deactivated: formFieldsValue.deactivated,
                                role_id: formFieldsValue.role_id,
                                name: user.name,
                                email: user.email,
                            });

                            navigateToUsers();
                        }}
                    >
                        <Form.Item name="deactivated" valuePropName="checked">
                            <h3>Deactivated</h3>
                            <Checkbox
                                defaultChecked={user.deactivated}
                                onChange={(e) => form.setFieldsValue({ deactivated: e.target.checked })}
                            />
                        </Form.Item>
                        <Form.Item name="role_id" rules={[{ required: true, message: "Please choose a role!" }]}>
                            <h3>Role</h3>
                            <Select
                                placeholder={"Role"}
                                size={"middle"}
                                defaultValue={roleMapping.get(user.role_id.toString())}
                                onChange={(value: number | string) => {
                                    form.setFieldsValue({ role_id: value });
                                }}
                            >
                                {Array.from(roleMapping).map(([key, value]) => {
                                    return (
                                        <Option key={key} value={key}>
                                            {value}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <div className={"inline-buttons-container"}>
                                <Button type={"primary"} htmlType={"submit"}>
                                    Save
                                </Button>
                                <Button onClick={navigateToUsers}>Cancel</Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    );
};
