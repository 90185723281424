import React, { FC } from "react";
import { FaClock, FaProjectDiagram, FaUser } from "react-icons/fa";
import { SettingCard } from "../../../components/SettingCard/View/SettingCard";
import { useSettingsVM } from "../ViewModel/SettingsVM";
import "./SettingsStyle.css";

export const SettingsView: FC = () => {
    const { navigateToUsers, navigateToProjects, navigateToTimeEntryTypes } = useSettingsVM();

    return (
        <div className="settings-container">
            <SettingCard label="Users" icon={FaUser} onButtonClick={() => navigateToUsers()} />
            <SettingCard label="Projects" icon={FaProjectDiagram} onButtonClick={() => navigateToProjects()} />
            <SettingCard label="Time Entry Types" icon={FaClock} onButtonClick={() => navigateToTimeEntryTypes()} />
        </div>
    );
};
