import React from "react";
import "./TotalCardStyle.css";
import { useTotalCardVM } from "../ViewModel/TotalCardVM";
import { DurationData } from "../../../util/hooks/useTimeEntriesDuration";

interface TotalCardProps {
    durationData: DurationData;
}

export const TotalCard = (props: TotalCardProps): JSX.Element => {
    const { isScrolling } = useTotalCardVM();
    const TOTAL_LABEL = "Total: ";

    return (
        <div className={!isScrolling ? "total-card-container" : "total-card-container total-card-container-scrolling"}>
            <div className={"total-card-total-hour"}>{TOTAL_LABEL + props.durationData.totalDuration}</div>
            <div className={"total-card-type-container"}>
                {props.durationData.typeDurationData.map((typeDuration, index) => {
                    return (
                        <div key={index} className={"total-card-type"}>
                            <div className={`total-card-type-color ${typeDuration.color}`} />
                            <div>{`${typeDuration.type}: ${typeDuration.duration}`}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
