import { useMemo } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { useNavigate } from "react-router-dom";
import { getAllProjects } from "../../../api/projectApi";
import { getUsers, User } from "../../../api/userApi";
import { getTimeEntryTypes, TimeEntryTypeRecord } from "../../../api/timeEntryTypeApi";
import { Form, FormInstance } from "antd";
import { useAppVM } from "../../../AppVM";
import { Project } from "../../../data/Project";

interface ReportsHeaderVM {
    projects: Project[];
    types: TimeEntryTypeRecord[];
    navigateToCreateTimeEntry: () => void;
    users: User[];
    form: FormInstance<any>;
}

export interface ReportsHeaderFormValue {
    projectName: string;
    type: string;
    user: string;
    dateRange?: moment.Moment[];
    pageSize: number;
    sortOrder: "asc" | "desc";
}

export const useReportsHeaderVM = (): ReportsHeaderVM => {
    const navigate = useNavigate();
    const { routes } = useAppVM();
    const [form] = Form.useForm();

    const navigateToCreateTimeEntry = () => {
        navigate(`${routes.timesheet}/create`);
    };

    const projectQuery: UseQueryResult<Project[], Error> = useQuery("Projects", () => getAllProjects());

    const projects: Project[] = useMemo<Project[]>(() => {
        if (projectQuery.isFetched && projectQuery.isSuccess) {
            return projectQuery.data.map((project: Project) => {
                return project;
            });
        }
        return [];
    }, [projectQuery]);

    const usersQuery: UseQueryResult<User[], Error> = useQuery("Users", () => {
        return getUsers();
    });

    const users: User[] = useMemo<User[]>(() => {
        if (usersQuery.isFetched && usersQuery.isSuccess) {
            return usersQuery.data.map((user: User) => {
                return user;
            });
        }
        return [];
    }, [usersQuery]);

    const typeQuery: UseQueryResult<TimeEntryTypeRecord[], Error> = useQuery("TimeEntryTypes", getTimeEntryTypes);

    const types: TimeEntryTypeRecord[] = useMemo<TimeEntryTypeRecord[]>(() => {
        if (typeQuery.isFetched && typeQuery.isSuccess) {
            return typeQuery.data.map((type: TimeEntryTypeRecord) => {
                return type;
            });
        }
        return [];
    }, [typeQuery]);

    return {
        projects,
        types,
        navigateToCreateTimeEntry,
        users,
        form,
    };
};
