import { useQuery, UseQueryResult } from "react-query";
import { TimeEntry } from "../../../data/TimeEntry";
import { getTimeEntries, TimeEntryRecord, TimeEntryRecordsData } from "../../../api/timeEntryApi";
import { Dispatch, useEffect, useMemo, useState } from "react";
import { convertMomentToDateString, getMonthRangeFromDate } from "../../../util/momentDateUtil";
import { FilterCriteria, useFilterTimeEntries } from "../../../util/hooks/useFilterTimeEntries";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { User } from "../../../api/userApi";
import moment from "moment";
import { getLocalStorageFilters } from "../../../components/CalendarHeader/View/CalendarHeader";

interface CalendarViewVM {
    timeEntries: TimeEntry[];
    navigate: NavigateFunction;
    setTimesheetFilters: Dispatch<React.SetStateAction<TimesheetFilters>>;
    timesheetFilters: TimesheetFilters;
    getUsernameHex: (username: string) => string;
}

export interface TimesheetFilters {
    username: string | undefined;
    project: string | undefined;
    type: string | undefined;
    dateRange: moment.Moment[] | undefined;
    pageSize: number;
    sortOrder: "asc" | "desc";
    viewMode: "dayGridMonth" | "dayGridWeek";
    date?: moment.Moment;
}

interface TimesheetVMProps {
    user: User | null;
}

const getInitialTimesheetFilters = (email: string | undefined): TimesheetFilters => {
    const filters = getLocalStorageFilters();

    if (filters) {
        return filters;
    } else {
        return {
            username: email,
            project: undefined,
            type: undefined,
            dateRange: getMonthRangeFromDate(moment()),
            pageSize: 200,
            sortOrder: "desc",
            viewMode: "dayGridMonth",
            date: moment(),
        };
    }
};

export function useCalendarViewVM(props: TimesheetVMProps): CalendarViewVM {
    const [timesheetFilters, setTimesheetFilters] = useState<TimesheetFilters>(() =>
        getInitialTimesheetFilters(props.user?.email)
    );

    useEffect(() => {
        setTimesheetFilters({
            username: props.user?.email,
            project: timesheetFilters.project,
            type: timesheetFilters.type,
            dateRange: timesheetFilters.dateRange,
            pageSize: timesheetFilters.pageSize,
            sortOrder: timesheetFilters.sortOrder,
            viewMode: timesheetFilters.viewMode,
            date: timesheetFilters.date,
        });
    }, [props.user]);

    const timeEntryQuery: UseQueryResult<TimeEntryRecordsData, Error> = useQuery(
        ["TimeEntryList", timesheetFilters],
        () => {
            let start_date;
            let end_date;
            if (timesheetFilters.date) {
                const range = getMonthRangeFromDate(timesheetFilters.date);

                start_date = convertMomentToDateString(range[0]);
                end_date = convertMomentToDateString(range[1]);
            }

            return getTimeEntries(
                timesheetFilters.username,
                0,
                timesheetFilters.pageSize,
                timesheetFilters.project,
                timesheetFilters.type,
                start_date,
                end_date,
                timesheetFilters.sortOrder
            );
        },
        {
            enabled: !!props.user, // Skip the query if user is null or undefined
        }
    );

    const timeEntries: TimeEntry[] = useMemo<TimeEntry[]>(() => {
        if (timeEntryQuery.isFetched && timeEntryQuery.isSuccess) {
            return timeEntryQuery.data.data.map((record: TimeEntryRecord) => {
                return new TimeEntry(record);
            });
        }
        return [];
    }, [timeEntryQuery]);

    const [filterCriteria] = useState<FilterCriteria>({});

    const { filteredTimeEntries } = useFilterTimeEntries(timeEntries, filterCriteria);

    const navigate = useNavigate();

    /* eslint-disable no-bitwise */

    const getUsernameHex = (username: string): string => {
        // Hash the input string to a numeric value
        let hash = 0;
        for (let i = 0; i < username.length; i++) {
            hash = username.charCodeAt(i) + ((hash << 5) - hash);
        }

        // Adjust the range to generate darker colors
        const minColorValue = 50; // Minimum color value
        const maxColorValue = 300; // Maximum color value

        // Generate RGB components based on the hash value
        const r = Math.abs((hash % (maxColorValue - minColorValue)) + minColorValue);
        const g = Math.abs(((hash * 2) % (maxColorValue - minColorValue)) + minColorValue);
        const b = Math.abs(((hash * 3) % (maxColorValue - minColorValue)) + minColorValue);

        // Convert RGB values to 2-digit hex and concatenate them
        const color = `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b
            .toString(16)
            .padStart(2, "0")}`;

        return color;
    };

    /* eslint-enable no-bitwise */

    return {
        timeEntries: filteredTimeEntries,
        navigate,
        setTimesheetFilters,
        timesheetFilters,
        getUsernameHex,
    };
}
