import { TimeEntry } from "../data/TimeEntry";
import { TimeEntryType } from "../data/TimeEntryType";

export const defaultTimeEntryTypeColors = [
    "corduroy",
    "watermelon",
    "orange",
    "purple",
    "lima",
    "blue",
    "neptune",
    "nandor",
];

export const getTimeEntryTypeColorForTimeEntry = (timeEntry: TimeEntry, timeEntryTypes: TimeEntryType[]) => {
    const filteredTimeEntryTypes = timeEntryTypes.filter((timeEntryType) => timeEntryType.name === timeEntry.type);

    if (filteredTimeEntryTypes.length > 0) {
        return filteredTimeEntryTypes[0].color;
    }
    // In case the type of the time entry is deleted return a default color for now.
    return "nandor";
};
