import { client } from "./client";
import { User } from "./userApi";

export interface AuthRecord {
    access_token: number;
    token_type: string;
    user: User;
}

export async function auth(): Promise<Response> {
    const response = await client.get<Response>("/auth");

    return response.data;
}

export async function authCallback(extraUrl: string): Promise<AuthRecord> {
    const response = await client.get<AuthRecord>(`/auth/callback${extraUrl}`);
    console.log(response.data);
    return response.data;
}
