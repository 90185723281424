import { Form, FormInstance } from "antd";
import { useEffect, useMemo } from "react";
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { addProject, editProject, ProjectRecord } from "../../../api/projectApi";
import { useAppVM } from "../../../AppVM";
import { Project } from "../../../data/Project";
import { getStatuses, Status } from "../../../api/statusApi";
import { Team, deleteTeam, getTeams } from "../../../api/teamApi";

interface ProjectFormVM {
    project: Project;
    statuses: Status[];
    addProjectMutation: UseMutationResult<void, unknown, ProjectRecord, unknown>;
    editProjectMutation: UseMutationResult<void, unknown, ProjectRecord, unknown>;
    isEditing: boolean;
    form: FormInstance<any>;
    getCurrentStatusName: (id: number) => string;
    getStatusIdFromName: (statusName: string) => number;
    navigateToProjects: () => void;
    navigateToProjectMemberForm: (project: Project) => void;
    navigateToTeamForm: (project: Project) => void;
    navigateToEditTeam: (team: Team) => void;
    teams: Team[];
    deleteTeamMutation: UseMutationResult<void, unknown, number, unknown>;
}

export interface ProjectFormValue {
    name: string;
    description: string;
    statusId: number;
    startDate: string;
    endDate: string;
    isArchived: boolean;
}

export const useProjectFormVM = (): ProjectFormVM => {
    const location = useLocation();
    const navigate = useNavigate();
    const { routes } = useAppVM();
    const [form] = Form.useForm();

    const queryClient = useQueryClient();

    const project = useMemo(() => {
        return location.state as Project;
    }, [location.state]);

    const addProjectMutation = useMutation(addProject, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["Projects"]);
        },
    });

    const editProjectMutation = useMutation(editProject, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["Projects"]);
        },
    });

    const isEditing = useMemo(() => {
        return !!location.state;
    }, [location.state]);

    const statusQuery: UseQueryResult<Status[], Error> = useQuery("Statuses", getStatuses);

    const statuses: Status[] = useMemo<Status[]>(() => {
        if (statusQuery.isFetched && statusQuery.isSuccess) {
            return statusQuery.data.map((status: Status) => {
                return status;
            });
        }
        return [];
    }, [statusQuery]);

    useEffect(() => {
        if (project) {
            form.setFieldsValue({
                name: project.name,
                description: project.description,
                startDate: project.startDateTimeStamp,
                endDate: project.endDateTimeStamp,
                statusId: project.statusId,
                isArchived: project.isArchived,
            });
        }
    }, [project]);

    const navigateToProjects = () => {
        navigate(routes.projects);
    };

    const navigateToProjectMemberForm = (currentProject: Project) => {
        navigate(`${routes.projectMembers}/create/${currentProject.id}`, {
            state: {
                ...currentProject,
            },
        });
    };

    const navigateToTeamForm = (currentProject: Project) => {
        navigate(`${routes.projects}/teams/create/${currentProject.id}`, {
            state: {
                project: currentProject,
            },
        });
    };

    const getCurrentStatusName = (id: number) => {
        const currentStatusList = statuses.filter((status) => status.id === id);
        return currentStatusList[0].name;
    };

    const getStatusIdFromName = (statusName: string) => {
        const filteredStatusList = statuses.filter((status) => status.name === statusName);
        return filteredStatusList[0].id;
    };

    const TeamsQuery: UseQueryResult<Team[], Error> = useQuery("Teams", () => getTeams(project.id));

    const teams: Team[] = useMemo<Team[]>(() => {
        if (TeamsQuery.isFetched && TeamsQuery.isSuccess) {
            return TeamsQuery.data;
        }
        return [];
    }, [TeamsQuery]);

    const deleteTeamMutation = useMutation(deleteTeam, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([`Teams`]);
        },
    });

    const navigateToEditTeam = (teamToEdit: Team) => {
        navigate(`${routes.projects}/teams/edit/${teamToEdit.id}`, {
            state: {
                team: teamToEdit,
            },
        });
    };

    return {
        project,
        statuses,
        addProjectMutation,
        editProjectMutation,
        isEditing,
        form,
        getCurrentStatusName,
        getStatusIdFromName,
        navigateToProjects,
        navigateToProjectMemberForm,
        navigateToTeamForm,
        teams,
        deleteTeamMutation,
        navigateToEditTeam,
    };
};
