import { Button } from "antd";
import React from "react";
import { IconType } from "react-icons";
import "./SettingCardStyle.css";

interface SettingCardProps {
    label: string;
    icon: IconType;
    onButtonClick: () => void;
}

export const SettingCard = (props: SettingCardProps) => {
    return (
        <div className="setting-card-container">
            <div className="setting-card-id">
                <props.icon className={"setting-card-icon"} />
                <text className={"setting-card-label"}>{props.label}</text>
            </div>
            <Button onClick={props.onButtonClick} type="primary">
                See All
            </Button>
        </div>
    );
};
