import { TimeEntry } from "../../data/TimeEntry";
import { useMemo } from "react";
import { getHoursAndMinutesFromNumber, getSumOfDuration } from "../timeDurationConversionUtil";
import { TimeEntryType } from "../../data/TimeEntryType";
import { getTimeEntryTypeColorForTimeEntry } from "../timeEntryTypeColors";

interface TimeEntriesDuration {
    durationData: DurationData;
}

export interface DurationData {
    totalDuration: string;
    typeDurationData: TypeDurationData[];
}

interface TypeDurationData {
    color: string;
    duration: string;
    type: string;
}

export const useTimeEntriesDuration = (
    timeEntries: TimeEntry[],
    timeEntryTypes: TimeEntryType[]
): TimeEntriesDuration => {
    const durationData = useMemo<DurationData>(() => {
        let typeDurationDataObject: { [key: string]: { duration: number; color: string } } = {};

        timeEntries.map((timeEntry) => {
            if (typeDurationDataObject[timeEntry.type]) {
                typeDurationDataObject = {
                    ...typeDurationDataObject,
                    [timeEntry.type]: {
                        duration: getSumOfDuration(typeDurationDataObject[timeEntry.type].duration, timeEntry.duration),
                        color: getTimeEntryTypeColorForTimeEntry(timeEntry, timeEntryTypes),
                    },
                };
            } else {
                typeDurationDataObject = {
                    ...typeDurationDataObject,
                    [timeEntry.type]: {
                        duration: timeEntry.duration,
                        color: getTimeEntryTypeColorForTimeEntry(timeEntry, timeEntryTypes),
                    },
                };
            }
        });

        const typeDurationData: TypeDurationData[] = [];
        let totalDuration = 0;
        if (timeEntries.length > 0) {
            Object.keys(typeDurationDataObject).forEach((key) => {
                totalDuration = getSumOfDuration(totalDuration, typeDurationDataObject[key].duration);
                typeDurationData.push({
                    color: typeDurationDataObject[key].color,
                    duration: getHoursAndMinutesFromNumber(typeDurationDataObject[key].duration),
                    type: key,
                });
            });
        }

        return {
            totalDuration: getHoursAndMinutesFromNumber(totalDuration),
            typeDurationData: typeDurationData,
        };
    }, [timeEntries, timeEntryTypes]);

    return {
        durationData,
    };
};
