import { useState } from "react";

interface PaginationHook {
    paginationState: PaginationState;
    handlePaginationChange: (value: number) => void;
    resetPaginationState: () => void;
}

export interface PaginationState {
    minValue: number;
    maxValue: number;
    pageSize: number;
    currentPage: number;
}

export const usePagination = (): PaginationHook => {
    const pageSize = 10;
    const initialPaginationState: PaginationState = {
        minValue: 0,
        maxValue: pageSize,
        pageSize: pageSize,
        currentPage: 1,
    };

    const [paginationState, setPaginationState] = useState<PaginationState>(initialPaginationState);

    const handlePaginationChange = (value: number) => {
        setPaginationState({
            ...paginationState,
            minValue: (value - 1) * paginationState.pageSize,
            maxValue: value * paginationState.pageSize,
            currentPage: value,
        });
    };

    const resetPaginationState = () => {
        setPaginationState(initialPaginationState);
    };

    return {
        paginationState,
        resetPaginationState,
        handlePaginationChange,
    };
};
