import moment from "moment";

export function convertMomentToDateString(momentDate: moment.Moment, format?: "MM/DD/YYYY" | "YYYY/MM/DD"): string {
    const date = momentDate.toDate();

    // console.log(date);
    const yyyy = date.getFullYear().toString();
    let mm = (date.getMonth() + 1).toString(); // Months start at 0!
    let dd = date.getDate().toString();

    if (parseInt(dd, 10) < 10) dd = `0${dd}`;
    if (parseInt(mm, 10) < 10) mm = `0${mm}`;

    const formattedDate = `${mm}/${dd}/${yyyy}`;

    switch (format) {
        case "MM/DD/YYYY":
            return formattedDate;
        case "YYYY/MM/DD":
            return `${yyyy}/${mm}/${dd}`;
        default:
            return formattedDate;
    }
}

export function convertDateStringToMoment(dateString: string, format?: "MM/DD/YYYY" | "YYYY/MM/DD"): moment.Moment {
    if (!format) {
        return moment(dateString, "MM/DD/YYYY");
    }
    return moment(dateString, format);
}

export function getWeekRangeFromDate(date: moment.Moment) {
    const selectedDate = moment(date);
    const startDate = moment(selectedDate).isoWeekday(1).startOf("isoWeek");
    const endDate = moment(startDate).add(6, "days").endOf("day");

    return [startDate, endDate];
}

export function getMonthRangeFromDate(date: moment.Moment) {
    const selectedDate = moment(date);
    const startDate = moment(selectedDate).startOf("month");
    const endDate = moment(selectedDate).endOf("month");

    return [startDate, endDate];
}
