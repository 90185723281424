import React, { FC } from "react";
import { useProjectsVM } from "../ViewModel/ProjectsVM";
import { ProjectCard } from "../../../components/ProjectCard/View/ProjectCard";
import { FormHeader } from "../../../components/FormHeader/View/FormHeader";
import { Button, Switch } from "antd";
import { FaPlus } from "react-icons/fa";

export const ProjectsView: FC = () => {
    const {
        projects,
        deleteProjectMutation,
        navigateToCreateProject,
        navigateToEditProject,
        navigateToProjectInfo,
        navigateToSettings,
        showArchived,
        setShowArchived,
    } = useProjectsVM();

    return (
        <>
            <FormHeader label={"Projects"} onReturn={() => navigateToSettings()} />
            <div className={"container"}>
                <label>{showArchived ? "Archived " : "Current "}</label>
                <Switch checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
                {projects.map((project, key) => {
                    return (
                        <ProjectCard
                            project={project}
                            key={key}
                            onDelete={() => deleteProjectMutation.mutate(project.id)}
                            onEdit={() => navigateToEditProject(project)}
                            onInfo={() => navigateToProjectInfo(project)}
                        />
                    );
                })}
                <Button type={"primary"} icon={<FaPlus />} onClick={() => navigateToCreateProject()}>
                    Add Project
                </Button>
            </div>
        </>
    );
};
