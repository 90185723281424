import React from "react";
import "./IconButtonStyle.css";
import { IconType } from "react-icons";

interface IconButtonProps {
    icon: IconType;
    onClick: () => void;
}

export const IconButton = (props: IconButtonProps): JSX.Element => {
    return (
        <button className={"icon-btn"} onClick={() => props.onClick()}>
            {<props.icon className={"icon-btn-icon"} />}
        </button>
    );
};
