import React from "react";

import "./DeleteConfirmationModal.css";
import { FaArrowRight, FaTrash } from "react-icons/fa";

interface DeleteConfirmationModalProps {
    text: string;
    onAccept: () => void;
    onDecline: () => void;
}

export const DeleteConfirmationModal = (props: DeleteConfirmationModalProps): JSX.Element => {
    return (
        <div className="delete-confirmation-body">
            <div className={"primary-card-header watermelondark"}>Deleting</div>
            <div className="delete-confirmation-content">
                <p>{props.text}</p>
                <div className="delete-confirmation-buttons">
                    <button className="ant-btn ant-btn-danger" onClick={props.onAccept}>
                        <FaTrash />
                        Delete
                    </button>
                    <button className="ant-btn ant-btn-primary" onClick={props.onDecline}>
                        <FaArrowRight />
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};
