import { TimeEntry } from "../../data/TimeEntry";
import { useMemo } from "react";

interface FilteredTimeEntries {
    filteredTimeEntries: TimeEntry[];
}

export interface FilterCriteria {
    startDateTimeStamp?: string;

    endDateTimeStamp?: string;
    projectName?: string;
    type?: string;
    user?: string;
}

export const useFilterTimeEntries = (timeEntries: TimeEntry[], criteria: FilterCriteria): FilteredTimeEntries => {
    const filteredTimeEntries = useMemo<TimeEntry[]>(() => {
        const criteriaKeys = Object.keys(criteria).filter((key) => criteria[key as keyof FilterCriteria]);

        if (criteriaKeys.length === 0) {
            return timeEntries;
        }

        const isDateFilterActive = criteriaKeys.includes("startDateTimeStamp");

        return timeEntries.filter((timeEntry) => {
            if (!isDateFilterActive) {
                if (
                    (!criteria.projectName || criteria.projectName === timeEntry.projectName) &&
                    (!criteria.type || criteria.type === timeEntry.type) &&
                    (!criteria.user || criteria.user === timeEntry.createdBy)
                ) {
                    return timeEntry;
                }
            } else {
                if (
                    new Date(criteria.startDateTimeStamp as string) <= new Date(timeEntry.startDateTimeStamp) &&
                    new Date(criteria.endDateTimeStamp as string) >= new Date(timeEntry.startDateTimeStamp) &&
                    (!criteria.projectName || criteria.projectName === timeEntry.projectName) &&
                    (!criteria.type || criteria.type === timeEntry.type) &&
                    (!criteria.user || criteria.user === timeEntry.createdBy)
                ) {
                    return timeEntry;
                }
            }
        });
    }, [timeEntries, criteria]);

    return {
        filteredTimeEntries,
    };
};
