import { client } from "./client";

export interface User {
    id: number;
    name: string;
    email: string;
    role_id: number;
    deactivated: boolean;
}

interface UsersData {
    data: User[];
}

export const roleMapping = new Map(Object.entries({ 1: "Admin", 2: "Manager", 3: "User" }));

export async function getUser(): Promise<User> {
    const response = await client.get<User>("/user/me");
    return response.data;
}

export async function getUsers(search_word?: string): Promise<User[]> {
    const requestBody = {
        search_word: search_word,
    };

    const response = await client.get<UsersData>("/users", { params: requestBody });
    return response.data.data;
}

export async function getUserById(id: number): Promise<User> {
    const response = await client.get<User>(`/user/${id}`);
    return response.data;
}

export async function addUser(user: User): Promise<void> {
    await client.post<User>(`/user`, user);
}

export async function editUser(user: User): Promise<void> {
    await client.put<User>(`/user/${user.id}`, user);
}

export async function deleteUser(id: number): Promise<void> {
    await client.delete<User>(`/user/${id}`);
}
