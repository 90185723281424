import { Form, FormInstance } from "antd";
import { useContext, useEffect, useMemo } from "react";
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { getProjects } from "../../../api/projectApi";
import { addTimeEntry, editTimeEntry, TimeEntryRecord } from "../../../api/timeEntryApi";
import { getTimeEntryTypes, TimeEntryTypeRecord } from "../../../api/timeEntryTypeApi";
import { TimeEntry } from "../../../data/TimeEntry";
import { Project } from "../../../data/Project";
import moment from "moment";
import { UserContext, UserContextType } from "../../../context/UserContextProvider";
import { User } from "../../../api/userApi";

interface TimeEntryFormVM {
    timeEntry: TimeEntry;
    addTimeEntryMutation: UseMutationResult<void, unknown, TimeEntryRecord, unknown>;
    editTimeEntryMutation: UseMutationResult<void, unknown, TimeEntryRecord, unknown>;
    isEditing: boolean;
    projects?: Project[];
    types?: TimeEntryTypeRecord[];
    form: FormInstance<any>;
    navigateBack: () => void;
    user: User | null;
}

export interface TimeEntryFormValue {
    duration: number;
    projectName: string;
    date: number;
    type: string;
    description: string;
}

export const useTimeEntryFormVM = (): TimeEntryFormVM => {
    const location = useLocation();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { user } = useContext(UserContext) as UserContextType;

    const queryClient = useQueryClient();

    const timeEntry = useMemo(() => {
        return location.state as TimeEntry;
    }, [location.state]);

    const addTimeEntryMutation = useMutation(addTimeEntry, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["TimeEntryList"]);
        },
    });

    const editTimeEntryMutation = useMutation(editTimeEntry, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(["TimeEntryList"]);
        },
    });

    const projectQuery: UseQueryResult<Project[], Error> = useQuery("Projects", () => getProjects(user?.email));

    const projects: Project[] = useMemo<Project[]>(() => {
        if (projectQuery.isFetched && projectQuery.isSuccess) {
            return projectQuery.data.map((project: Project) => {
                return project;
            });
        }
        return [];
    }, [projectQuery]);

    const typeQuery: UseQueryResult<TimeEntryTypeRecord[], Error> = useQuery("TimeEntryTypes", getTimeEntryTypes);

    const types: TimeEntryTypeRecord[] = useMemo<TimeEntryTypeRecord[]>(() => {
        if (typeQuery.isFetched && typeQuery.isSuccess) {
            return typeQuery.data.map((type: TimeEntryTypeRecord) => {
                return type;
            });
        }
        return [];
    }, [typeQuery]);

    const isEditing = useMemo(() => {
        return !!location.state;
    }, [location.state]);

    useEffect(() => {
        if (timeEntry) {
            form.setFieldsValue({
                projectName: timeEntry.projectName,
                description: timeEntry.description,
                duration: timeEntry.duration,
                date: timeEntry.startDateTimeStamp,
                type: timeEntry.type,
            });
        } else {
            form.setFieldsValue({
                date: moment(),
            });
        }
    }, [timeEntry]);

    const navigateBack = () => {
        navigate(-1);
    };

    return {
        timeEntry,
        addTimeEntryMutation,
        editTimeEntryMutation,
        isEditing,
        projects,
        types,
        form,
        navigateBack,
        user,
    };
};
