import React, { useState, useEffect } from "react";
import { auth } from "../../../api/authApi";
import "./SignInButton.css";
import GoogleLogo from "../../../icons/GoogleLogo.png";

const SignInButton = () => {
    const [loginUrl, setLoginUrl] = useState<string>("");

    useEffect(() => {
        auth()
            .then((data) => setLoginUrl(data.url))
            .catch((error) => console.error(error));
    }, []);

    return (
        <button className="signin-button">
            {loginUrl != null && (
                <>
                    <img className="google-logo" src={GoogleLogo} />
                    <a href={loginUrl}> Continue with Google</a>
                </>
            )}
        </button>
    );
};

export default SignInButton;
