import React, { FC, useState } from "react";
import { TimeEntry } from "../../../data/TimeEntry";
import { Table } from "antd";
import { NavigateFunction } from "react-router-dom";
import { RoutePaths } from "../../../AppVM";
import { FaEdit, FaTrash } from "react-icons/fa";
import { DeleteConfirmationModal } from "../../DeleteConfirmationModal/View/DeleteConfirmationModal";

export interface TimeEntryTableProps {
    timeEntries: TimeEntry[];
    onDelete: (id: number) => void;
    navigate: NavigateFunction;
    routes: RoutePaths;
}

export const TimeEntryTable: FC<TimeEntryTableProps> = (props: TimeEntryTableProps) => {
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);
    const [selectedTimeEntry, setSelectedTimeEntry] = useState<TimeEntry | undefined>();

    const tableColumns = [
        {
            title: "Username",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Project",
            dataIndex: "projectName",
            key: "projectName",
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
        },
        {
            title: "Duration",
            dataIndex: "duration",
            key: "duration",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Date",
            dataIndex: "startDateTimeStamp",
            key: "startDateTimeStamp",
        },
        {
            title: "Action",
            dataIndex: "",
            key: "x",
            render: (record: TimeEntry) => (
                <div className={"primary-card-content-action-panel"}>
                    <button
                        className={"primary-card-edit-btn"}
                        onClick={() =>
                            props.navigate(`${props.routes.timesheet}/edit/${record.id}`, {
                                state: {
                                    ...record,
                                },
                            })
                        }
                    >
                        <FaEdit />
                    </button>
                    <button
                        className={"primary-card-delete-btn"}
                        onClick={() => {
                            setSelectedTimeEntry(record);
                            setDeleteConfirmationOpen(true);
                        }}
                    >
                        <FaTrash />
                    </button>
                </div>
            ),
        },
    ];

    return (
        <>
            {deleteConfirmationOpen && (
                <DeleteConfirmationModal
                    text={`Are you sure you want to delete time entry for ${
                        selectedTimeEntry ? selectedTimeEntry.startDateTimeStamp : ""
                    } by ${selectedTimeEntry ? selectedTimeEntry.createdBy : ""} ?`}
                    onAccept={() => {
                        props.onDelete(selectedTimeEntry ? selectedTimeEntry.id : 0);
                        setDeleteConfirmationOpen(false);
                    }}
                    onDecline={() => setDeleteConfirmationOpen(false)}
                />
            )}
            <Table
                dataSource={props.timeEntries}
                columns={tableColumns}
                pagination={false}
                size={"middle"}
                scroll={{ y: "70vh" }}
            />
        </>
    );
};
