import React, { FC, useState } from "react";
import { ReportsHeader } from "../../../components/ReportsHeader/View/ReportsHeader";
import { Button, Pagination, Select, Spin } from "antd";
import { TimeEntryCard } from "../../../components/TimeEntryCard/View/TimeEntryCard";
import { useReportsVM } from "../ViewModel/ReportsVM";
import { useAppVM } from "../../../AppVM";
import { ScrollingBody } from "../../../components/ScrollingBody/View/ScrollingBody";
import { CSVLink } from "react-csv";
import { getHoursAndMinutesFromNumber } from "../../../util/timeDurationConversionUtil";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { TimeEntryTable } from "../../../components/TimeEntryTable/View/TimeEntryTable";
import { Option } from "antd/lib/mentions";
import { ViewMode } from "../../../util/viewModeUtil";
import { useReportsContext } from "../../../context/ReportsContextProvider";
import moment from "moment";

export const ReportsView: FC = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Card);

    const { filters, setFilters } = useReportsContext();

    const {
        timeEntries,
        deleteTimeEntryMutation,
        loading,
        navigate,
        getTimeEntryTypeColor,
        durationData,
        timeEntriesTotal,
    } = useReportsVM({ user: null, page: currentPage });
    const { routes } = useAppVM();

    const csvData = [
        [
            "ID",
            "Username",
            "ProjectName",
            "Description",
            "Duration",
            "StartTime",
            "EndTime",
            "Type",
            "CreatedBy",
            "LastUpdatedBy",
        ],
        ...timeEntries.map(
            ({
                id,
                username,
                projectName,
                description,
                duration,
                startDateTimeStamp,
                endDateTimeStamp,
                type,
                createdBy,
                lastUpdatedBy,
            }) => [
                id,
                username,
                projectName,
                description,
                getHoursAndMinutesFromNumber(duration),
                startDateTimeStamp,
                endDateTimeStamp,
                type,
                createdBy,
                lastUpdatedBy,
            ]
        ),
    ];

    csvData.push([`Total hours: ${durationData.totalDuration}`]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <>
            <ScrollingBody
                header={
                    <ReportsHeader
                        durationData={durationData}
                        setReportsFilters={setFilters}
                        reportsFilters={filters}
                    />
                }
                footer={
                    <div className={"pagination-bottom-container"}>
                        <Pagination
                            total={timeEntriesTotal}
                            current={currentPage}
                            pageSize={filters.pageSize}
                            onChange={handlePageChange}
                        />
                    </div>
                }
            >
                <div className="timesheet-body-container">
                    <div className={"reports-button-container"}>
                        <Button type={"primary"}>
                            <CSVLink
                                className="pdf-export-btn"
                                filename={`diasheets-${moment().format("YYYY-MM-DD")}.csv`}
                                data={csvData}
                            >
                                <FaArrowAltCircleDown /> Export to CSV
                            </CSVLink>
                        </Button>
                        <Select
                            className="view-select"
                            defaultValue={viewMode}
                            onChange={(value: ViewMode) => setViewMode(value)}
                        >
                            <Option value={ViewMode.Card}>Card view</Option>
                            <Option value={ViewMode.Table}>Table view</Option>
                        </Select>
                    </div>
                    {loading && (
                        <div className={"spinner-container"}>
                            <Spin />
                        </div>
                    )}
                    {viewMode === ViewMode.Card
                        ? timeEntries.map((item, index) => {
                              return (
                                  <TimeEntryCard
                                      key={index}
                                      timeEntry={item}
                                      color={getTimeEntryTypeColor(item)}
                                      onDelete={deleteTimeEntryMutation.mutate}
                                      onEdit={() =>
                                          navigate(`${routes.timesheet}/edit/${item.id}`, {
                                              state: {
                                                  ...item,
                                              },
                                          })
                                      }
                                  />
                              );
                          })
                        : undefined}
                    {viewMode === ViewMode.Table ? (
                        <TimeEntryTable
                            timeEntries={timeEntries}
                            onDelete={deleteTimeEntryMutation.mutate}
                            navigate={navigate}
                            routes={routes}
                        />
                    ) : undefined}
                </div>
            </ScrollingBody>
        </>
    );
};
