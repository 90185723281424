import { client } from "./client";

export interface TimeEntryTypeRecord {
    id: number;
    name: string;
    description: string;
}

interface TimeEntryTypesData {
    data: TimeEntryTypeRecord[];
}

export async function getTimeEntryTypes(): Promise<TimeEntryTypeRecord[]> {
    const response = await client.get<TimeEntryTypesData>("/type");
    return response.data.data;
}

export async function getTimeEntryTypeById(id: number): Promise<TimeEntryTypeRecord> {
    const response = await client.get<TimeEntryTypeRecord>(`/type/${id}`);
    return response.data;
}

export async function addTimeEntryType(type: TimeEntryTypeRecord): Promise<void> {
    await client.post<TimeEntryTypeRecord>(`/type`, type);
}

export async function editTimeEntryType(type: TimeEntryTypeRecord): Promise<void> {
    await client.put<TimeEntryTypeRecord>(`/type/${type.id}`, type);
}

export async function deleteTimeEntryType(id: number): Promise<void> {
    await client.delete<TimeEntryTypeRecord>(`/type/${id}`);
}
