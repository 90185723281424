import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Header } from "../../Header/View/Header";
import "./FormHeaderStyle.css";

interface FormHeaderProps {
    label: string;
    onReturn: () => void;
}
export const FormHeader = (props: FormHeaderProps): JSX.Element => {
    return (
        <Header>
            <div className={"form-header-container"}>
                <button className={"return-button"} onClick={props.onReturn}>
                    <FaArrowLeft />
                </button>

                <div>{props.label}</div>
            </div>
        </Header>
    );
};
