import { useLocation, useNavigate } from "react-router-dom";
import { useAppVM } from "../../../AppVM";
import { Project } from "../../../data/Project";
import { useMemo } from "react";
import { UseMutationResult, UseQueryResult, useMutation, useQuery } from "react-query";
import { Team, deleteTeam, getTeams } from "../../../api/teamApi";
import { queryClient } from "../../../App";

interface ProjectInfoVM {
    project: Project;
    navigateToEditProject: (project: Project) => void;
    navigateToProjects: () => void;
    teams: Team[];
    deleteTeamMutation: UseMutationResult<void, unknown, number, unknown>;
    navigateToEditTeam: (teamToEdit: Team) => void;
}

export function useProjectInfoVM(): ProjectInfoVM {
    const location = useLocation();
    const navigate = useNavigate();
    const { routes } = useAppVM();

    const project = useMemo(() => {
        return location.state as Project;
    }, [location.state]);

    const navigateToEditProject = (projectToEdit: Project) => {
        navigate(`${routes.projects}/edit/${projectToEdit.id}`, {
            state: {
                ...projectToEdit,
            },
        });
    };

    const navigateToEditTeam = (teamToEdit: Team) => {
        navigate(`${routes.projects}/teams/edit/${teamToEdit.id}`, {
            state: {
                team: teamToEdit,
            },
        });
    };

    const navigateToProjects = () => {
        navigate(routes.projects);
    };

    const TeamsQuery: UseQueryResult<Team[], Error> = useQuery("Teams", () => getTeams(project.id));

    const teams: Team[] = useMemo<Team[]>(() => {
        if (TeamsQuery.isFetched && TeamsQuery.isSuccess) {
            return TeamsQuery.data;
        }
        return [];
    }, [TeamsQuery]);

    const deleteTeamMutation = useMutation(deleteTeam, {
        onSuccess: async () => {
            await queryClient.invalidateQueries([`Teams`]);
        },
    });

    return {
        project,
        navigateToEditProject,
        navigateToProjects,
        teams,
        deleteTeamMutation,
        navigateToEditTeam,
    };
}
