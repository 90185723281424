import React from "react";
import { Button, Form, Input } from "antd";
import { TimeEntryTypeFormValue, useTimeEntryTypeFormVM } from "../ViewModel/TimeEntryTypeFormVM";
import { FormHeader } from "../../../components/FormHeader/View/FormHeader";

export const TimeEntryTypeForm = (): JSX.Element => {
    const { TextArea } = Input;
    const {
        timeEntryType,
        addTimeEntryTypeMutation,
        editTimeEntryTypeMutation,
        isEditing,
        form,
        navigateToTimeEntryTypes,
    } = useTimeEntryTypeFormVM();

    return (
        <>
            <FormHeader label={isEditing ? "Edit" : "Create"} onReturn={navigateToTimeEntryTypes} />
            <div className={"form-container"}>
                <div className={"form-centered"}>
                    <Form
                        name="timeEntryTypeForm"
                        form={form}
                        onFinish={() => {
                            const formFieldsValue = form.getFieldsValue() as TimeEntryTypeFormValue;
                            if (isEditing) {
                                editTimeEntryTypeMutation.mutate({
                                    ...timeEntryType,
                                    name: formFieldsValue.name,
                                    description: formFieldsValue.description,
                                });
                            } else {
                                addTimeEntryTypeMutation.mutate({
                                    id: 0,
                                    name: formFieldsValue.name,
                                    description: formFieldsValue.description,
                                });
                            }
                            navigateToTimeEntryTypes();
                        }}
                    >
                        <Form.Item
                            name="name"
                            rules={[{ required: true, message: "Please input your time entry type name!" }]}
                        >
                            <h3>Time entry type name</h3>
                            <Input
                                placeholder={"name"}
                                size={"middle"}
                                defaultValue={isEditing ? timeEntryType.name : undefined}
                                onChange={(value) => {
                                    form.setFieldsValue({ name: value.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            rules={[{ required: true, message: "Please input your time entry type description!" }]}
                        >
                            <h3>Description</h3>
                            <TextArea
                                rows={5}
                                placeholder={"Description"}
                                defaultValue={isEditing ? timeEntryType.description : ""}
                                onChange={(value) => {
                                    form.setFieldsValue({ description: value.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <div className={"inline-buttons-container"}>
                                <Button type={"primary"} htmlType={"submit"}>
                                    Save
                                </Button>
                                <Button onClick={navigateToTimeEntryTypes}>Cancel</Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    );
};
