import React, { useState } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
// import { getHoursAndMinutesFromNumber } from "../../../util/timeDurationConversionUtil";
// import { useExpansion } from "../../../util/hooks/useExpansion";
import { TimeEntryType } from "../../../data/TimeEntryType";
import { DeleteConfirmationModal } from "../../DeleteConfirmationModal/View/DeleteConfirmationModal";

interface TimeEntryTypeCardProps {
    timeEntryType: TimeEntryType;
    onDelete: (id: number) => void;
    onEdit: () => void;
}

export const TimeEntryTypeCard = (props: TimeEntryTypeCardProps): JSX.Element => {
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);

    return (
        <>
            {deleteConfirmationOpen && (
                <DeleteConfirmationModal
                    text={`Are you sure you want to delete ${props.timeEntryType.name}?`}
                    onAccept={() => {
                        props.onDelete(props.timeEntryType.id);
                        setDeleteConfirmationOpen(false);
                    }}
                    onDecline={() => setDeleteConfirmationOpen(false)}
                />
            )}
            <div className={"primary-card-container"}>
                <div className={"primary-card-content-time-entry-type"}>
                    <div className={"primary-card-content-item-centered"}>
                        <div className={`color-bubble ${props.timeEntryType.color}`} />
                    </div>
                    <div className={"primary-card-content-item-centered"}>{props.timeEntryType.name}</div>
                    <div className={"primary-card-description"}>{props.timeEntryType.description}</div>
                    <div className={"primary-card-content-action-panel"}>
                        <button
                            className={"primary-card-edit-btn"}
                            onClick={() => {
                                props.onEdit();
                            }}
                        >
                            <FaEdit />
                        </button>
                        <button className={"primary-card-delete-btn"} onClick={() => setDeleteConfirmationOpen(true)}>
                            <FaTrash />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
