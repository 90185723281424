import React, { useContext, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useCalendarViewVM } from "../ViewModel/CalendarViewVM";
import { UserContext, UserContextType } from "../../../context/UserContextProvider";
import { convertDateFormat } from "../../../util/dateTimeUtil";
import { ScrollingBody } from "../../../components/ScrollingBody/View/ScrollingBody";
import { CalendarHeader } from "../../../components/CalendarHeader/View/CalendarHeader";
import { useAppVM } from "../../../AppVM";
import { CalendarContext, CalendarContextType } from "../../../context/CalendarContextProvider";

const CalendarView: React.FC = () => {
    const { user } = useContext(UserContext) as UserContextType;
    const calendarRef = useRef<FullCalendar>(null);
    const { routes } = useAppVM();
    const { calendarDate, setCalendarDate, viewMode } = useContext(CalendarContext) as CalendarContextType;
    const { timeEntries, setTimesheetFilters, timesheetFilters, navigate, getUsernameHex } = useCalendarViewVM({
        user: user,
    });

    useEffect(() => {
        calendarRef.current?.getApi().gotoDate(calendarDate);

        calendarRef.current?.getApi().changeView(viewMode);
    }, [calendarDate, viewMode]);

    return (
        <ScrollingBody
            header={
                <CalendarHeader
                    setTimesheetFilters={setTimesheetFilters}
                    timesheetFilters={timesheetFilters}
                    setCurrentDate={setCalendarDate}
                    currentDate={calendarDate}
                    currentUser={user}
                />
            }
        >
            <div className="calendar-container">
                <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin]}
                    initialView="dayGridWeek"
                    firstDay={1}
                    initialDate={calendarDate}
                    headerToolbar={false}
                    height={"100%"}
                    eventBackgroundColor={"#54B6B6"}
                    eventClick={(info) =>
                        navigate(`${routes.timesheet}/edit/${info.event.id}`, {
                            state: {
                                ...timeEntries.at(Number(info.event.id)),
                            },
                        })
                    }
                    events={timeEntries.map((timeEntry, index) => ({
                        id: index.toString(),
                        title: `${timeEntry.projectName} - ${timeEntry.username}`,
                        start: convertDateFormat(timeEntry.startDateTimeStamp),
                        color: getUsernameHex(timeEntry.username),
                    }))}
                />
            </div>
        </ScrollingBody>
    );
};

export default CalendarView;
