import React from "react";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { Navigation } from "./components/Navigation/View/Navigation";
import { Route, Routes } from "react-router-dom";
import { HomeView } from "./Screens/Home/View/HomeView";
import { TimesheetView } from "./Screens/Timesheet/View/TimesheetView";
import { ReportsView } from "./Screens/Reports/View/ReportsView";
import { SettingsView } from "./Screens/Settings/View/SettingsView";
import "antd/dist/antd.css";
import { useAppVM } from "./AppVM";
import { TimeEntryForm } from "./Screens/TimeEntryForm/View/TimeEntryForm";
import { UsersView } from "./Screens/Users/View/UsersView";
import { ProjectsView } from "./Screens/Projects/View/ProjectsView";
import { TimeEntryTypesView } from "./Screens/TimeEntryTypes/View/TimeEntryTypesView";
import { ProjectForm } from "./components/ProjectForm/View/ProjectForm";
import { ProjectInfo } from "./Screens/ProjectInfo/View/ProjectInfo";
import { TimeEntryTypeForm } from "./Screens/TimeEntryTypeForm/View/TimeEntryTypeForm";
import SignInCallback from "./components/SignInCallback/SignInCallback";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import LoginView from "./Screens/Login/View/LoginView";
import { TeamMemberForm } from "./components/TeamMemberForm/View/TeamMemberForm";
import { TeamForm } from "./components/TeamForm/View/TeamForm";
import { UserEditForm } from "./components/UserEditForm/View/UserEditForm";
import CalendarView from "./Screens/Calendar/View/CalendarView";

export const queryClient: QueryClient = new QueryClient();

function App() {
    const { mainContainerClassName, setMainContainerClassName, routes } = useAppVM();

    return (
        <QueryClientProvider client={queryClient}>
            <div>
                <Navigation setMainContainerClassName={(className) => setMainContainerClassName(className)} />
                <div className={mainContainerClassName}>
                    <Routes>
                        <Route path={routes.signIn} element={<LoginView />} />
                        <Route path={routes.signInCallback} element={<SignInCallback />} />
                        <Route
                            path={routes.home}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2, 3]}>
                                    <HomeView />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={routes.timesheet}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2, 3]}>
                                    <TimesheetView />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={routes.reports}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2]}>
                                    <ReportsView />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={routes.settings}
                            element={
                                <ProtectedRoute allowedRoles={[1]}>
                                    <SettingsView />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${routes.timesheet}/edit/:id`}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2, 3]}>
                                    <TimeEntryForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${routes.timesheet}/create`}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2, 3]}>
                                    <TimeEntryForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${routes.settings}/users`}
                            element={
                                <ProtectedRoute allowedRoles={[1]}>
                                    <UsersView />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${routes.settings}/users/edit/:id`}
                            element={
                                <ProtectedRoute allowedRoles={[1]}>
                                    <UserEditForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${routes.settings}/projects`}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2]}>
                                    <ProjectsView />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${routes.settings}/timeEntryTypes`}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2]}>
                                    <TimeEntryTypesView />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${routes.projects}/edit/:id`}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2]}>
                                    <ProjectForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${routes.projects}/create`}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2]}>
                                    <ProjectForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${routes.projects}/:id`}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2]}>
                                    <ProjectInfo />
                                </ProtectedRoute>
                            }
                        />
                        {/* <Route
                            path={`${routes.users}/edit/:id`}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2]}>
                                    <UserForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${routes.users}/:id`}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2]}>
                                    <UserInfo />
                                </ProtectedRoute>
                            }
                        /> */}
                        <Route
                            path={`${routes.timeEntryTypes}/edit/:id`}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2]}>
                                    <TimeEntryTypeForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${routes.timeEntryTypes}/create`}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2]}>
                                    <TimeEntryTypeForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${routes.projectMembers}/create/:id`}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2]}>
                                    <TeamMemberForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${routes.projects}/teams/create/:id`}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2]}>
                                    <TeamForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${routes.projects}/teams/edit/:id`}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2]}>
                                    <TeamForm />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={`${routes.calendar}`}
                            element={
                                <ProtectedRoute allowedRoles={[1, 2, 3]}>
                                    <CalendarView />
                                </ProtectedRoute>
                            }
                        />
                    </Routes>
                </div>
            </div>
        </QueryClientProvider>
    );
}

export default App;
