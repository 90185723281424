import { Button, Form, Input } from "antd";
import { FormHeader } from "../../FormHeader/View/FormHeader";
import { TeamFormValue, useTeamFormVM } from "../ViewModel/TeamFormVM";
import React from "react";

export const TeamForm = (): JSX.Element => {
    const { addTeamMutation, form, navigateBack, editTeamMutation, navigationState } = useTeamFormVM();

    return (
        <>
            <FormHeader label={"Create a team"} onReturn={navigateBack} />
            <div className={"form-container"}>
                <div className={"form-centered"}>
                    <Form
                        name="projectForm"
                        form={form}
                        onFinish={() => {
                            const formFieldsValue = form.getFieldsValue() as TeamFormValue;

                            if (navigationState.team) {
                                editTeamMutation.mutate({
                                    id: navigationState.team.id,
                                    name: formFieldsValue.name,
                                    description: formFieldsValue.description,
                                    project_id: navigationState.team.project_id,
                                });
                            } else if (navigationState.project) {
                                addTeamMutation.mutate({
                                    id: 0,
                                    name: formFieldsValue.name,
                                    description: formFieldsValue.description,
                                    project_id: navigationState.project.id,
                                });
                            }

                            navigateBack();
                        }}
                    >
                        <Form.Item name="name" rules={[{ required: true, message: "Please add a name for the team!" }]}>
                            <h3>Name</h3>
                            <Input
                                placeholder={"Name"}
                                defaultValue={navigationState.team?.name}
                                size={"middle"}
                                onChange={(value) => {
                                    form.setFieldsValue({ name: value.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            rules={[{ required: true, message: "Please add a description for the team!" }]}
                        >
                            <h3>Description</h3>
                            <Input
                                placeholder={"Description"}
                                defaultValue={navigationState.team?.description}
                                size={"middle"}
                                onChange={(value) => {
                                    form.setFieldsValue({ description: value.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <div className={"inline-buttons-container"}>
                                <Button type={"primary"} htmlType={"submit"}>
                                    Save
                                </Button>
                                <Button onClick={navigateBack}>Cancel</Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </>
    );
};
