import React, { Dispatch } from "react";
import "./TimesheetHeaderStyle.css";
import { Select, DatePicker, Form, Button, Tooltip } from "antd";
import { IconButton } from "../../IconButton/View/IconButton";
import { FaCalendar, FaPlus, FaSearch } from "react-icons/fa";
import { TotalCard } from "../../TotalCard/View/TotalCard";
import { Header } from "../../Header/View/Header";
import { TimesheetHeaderFormValue, useTimesheetHeaderVM } from "../ViewModel/TimesheetHeaderVM";
import { DurationData } from "../../../util/hooks/useTimeEntriesDuration";
import { TimesheetFilters } from "../../../Screens/Timesheet/ViewModel/TimesheetVM";

interface TimesheetHeaderProps {
    durationData: DurationData;
    setTimesheetFilters: Dispatch<React.SetStateAction<TimesheetFilters>>;
    timesheetFilters: TimesheetFilters;
}

export const TimesheetHeader = (props: TimesheetHeaderProps): JSX.Element => {
    const { Option } = Select;
    const { projects, types, form, navigateToCreateTimeEntry } = useTimesheetHeaderVM();

    return (
        <Header>
            <div className={"input-bar"}>
                <Form name={"TimesheetHeaderForm"} layout={"inline"} form={form}>
                    <Form.Item name={"dateRange"}>
                        <DatePicker.RangePicker
                            suffixIcon={<FaCalendar />}
                            onChange={(value) => {
                                if (value) {
                                    form.setFieldsValue({
                                        dateRange: value,
                                    });
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item name="projectName">
                        <Select
                            placeholder={"Project"}
                            size={"middle"}
                            onChange={(value) => {
                                form.setFieldsValue({ projectName: value as string });
                            }}
                            allowClear
                        >
                            {projects.map((project) => {
                                return (
                                    <Option key={project.id} value={project.name}>
                                        {project.name}
                                    </Option>
                                );
                            })}
                            <Option key={"Research"} value={"Research"}>
                                {"Research"}
                            </Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="type">
                        <Select placeholder={"Type"} allowClear>
                            {types.map((type) => (
                                <Option key={type.id} value={type.name}>
                                    <Tooltip placement="right" title={type.description}>
                                        <span>{type.name}</span>
                                    </Tooltip>
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="pageSize">
                        <Select placeholder={"Page Size"}>
                            <Option value={30}>30</Option>
                            <Option value={50}>50</Option>
                            <Option value={100}>100</Option>
                            <Option value={150}>150</Option>
                            <Option value={200}>200</Option>
                            <Option value={300}>300</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="sortOrder">
                        <Select placeholder={"Sort by date"}>
                            <Option value="asc">Ascending</Option>
                            <Option value="desc">Descending</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <IconButton
                            icon={FaSearch}
                            onClick={() => {
                                const formFieldsValue = form.getFieldsValue() as TimesheetHeaderFormValue;
                                props.setTimesheetFilters({
                                    project: formFieldsValue.projectName,
                                    type: formFieldsValue.type,
                                    dateRange: !!formFieldsValue.dateRange ? formFieldsValue.dateRange : undefined,
                                    pageSize: !!formFieldsValue.pageSize
                                        ? formFieldsValue.pageSize
                                        : props.timesheetFilters.pageSize,
                                    sortOrder: formFieldsValue.sortOrder,
                                });
                            }}
                        />
                    </Form.Item>
                </Form>
                <Button type={"primary"} icon={<FaPlus />} onClick={() => navigateToCreateTimeEntry()}>
                    Add Time Entry
                </Button>
            </div>
            <TotalCard durationData={props.durationData} />
        </Header>
    );
};
