import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import "../../TimeEntryCard/View/TimeEntryCardStyle.css";
import { TeamMembership } from "../../../api/teamMembershipsApi";
import { DeleteConfirmationModal } from "../../DeleteConfirmationModal/View/DeleteConfirmationModal";

interface TeamMemberCardProps {
    teamMembership: TeamMembership;
    onDelete: (id: number) => void;
    teamName: string;
}

export const TeamMemberCard = (props: TeamMemberCardProps): JSX.Element => {
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false);

    return (
        <>
            {deleteConfirmationOpen && (
                <DeleteConfirmationModal
                    text={`Are you sure you want to delete ${props.teamMembership.username} from team ${props.teamName} ?`}
                    onAccept={() => {
                        props.onDelete(props.teamMembership.id);
                        setDeleteConfirmationOpen(false);
                    }}
                    onDecline={() => setDeleteConfirmationOpen(false)}
                />
            )}
            <div className={"primary-card-container"}>
                <div className={"primary-card-header nandor"}>{props.teamMembership.role}</div>
                <div>
                    <div className={"primary-card-content-team-member"}>{props.teamMembership.username}</div>
                    <div className={"primary-card-content-action-panel"}>
                        <button className={"primary-card-delete-btn"} onClick={() => setDeleteConfirmationOpen(true)}>
                            <FaTrash />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
