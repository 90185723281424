import { Button, DatePicker, Form, Select, Tooltip } from "antd";
import { Header } from "../../Header/View/Header";
import React, { Dispatch, useContext, useEffect } from "react";
import { FaCalendar, FaPlus, FaSearch } from "react-icons/fa";
import { IconButton } from "../../IconButton/View/IconButton";
import { TimesheetFilters } from "../../../Screens/Calendar/ViewModel/CalendarViewVM";
import { CalendarHeaderFormValue, useCalendarHeaderVM } from "../ViewModel/CalendarHeaderVM";
import moment from "moment";
import "../../TimesheetHeader/View/TimesheetHeaderStyle.css";
import { getMonthRangeFromDate, getWeekRangeFromDate } from "../../../util/momentDateUtil";
import { User } from "../../../api/userApi";
import { CalendarContext, CalendarContextType, ViewModes } from "../../../context/CalendarContextProvider";

interface CalendarHeaderProps {
    setTimesheetFilters: Dispatch<React.SetStateAction<TimesheetFilters>>;
    timesheetFilters: TimesheetFilters;
    setCurrentDate: React.Dispatch<React.SetStateAction<string>>;
    currentDate: string;
    currentUser: User | null;
}

const viewModeLabels: { [key in ViewModes]: string } = {
    [ViewModes.dayGridWeek]: "Week",
    [ViewModes.dayGridMonth]: "Month",
};

export const getLocalStorageFilters = () => {
    const savedCalendarFilters: string | null = localStorage.getItem("calendarFilters");
    if (savedCalendarFilters != null) {
        const parsedCalendarFilters = JSON.parse(savedCalendarFilters) as TimesheetFilters;

        return {
            date: moment(parsedCalendarFilters.date),
            projectName: parsedCalendarFilters.project,
            type: parsedCalendarFilters.type,
            user: parsedCalendarFilters.username,
            viewMode: parsedCalendarFilters.viewMode,
            username: parsedCalendarFilters.username,
            project: parsedCalendarFilters.project,
            dateRange: parsedCalendarFilters.dateRange,
            pageSize: parsedCalendarFilters.pageSize,
            sortOrder: parsedCalendarFilters.sortOrder,
        };
    }
};

export const CalendarHeader = (props: CalendarHeaderProps): JSX.Element => {
    const { Option } = Select;
    const { projects, types, form, navigateToCreateTimeEntry, users } = useCalendarHeaderVM({
        currentUser: props.currentUser,
    });

    const { viewMode, setViewMode } = useContext(CalendarContext) as CalendarContextType;

    const handleSearchByFilters = () => {
        const formFieldsValue = form.getFieldsValue() as CalendarHeaderFormValue;
        const selectedDate = moment(formFieldsValue.date || props.currentDate);

        let dateRange =
            viewMode === ViewModes.dayGridMonth
                ? getMonthRangeFromDate(selectedDate)
                : getWeekRangeFromDate(selectedDate);

        if (formFieldsValue.viewMode !== undefined) {
            dateRange =
                formFieldsValue.viewMode === ViewModes.dayGridMonth
                    ? getMonthRangeFromDate(selectedDate)
                    : getWeekRangeFromDate(selectedDate);
        }

        const pageSize = formFieldsValue.viewMode === ViewModes.dayGridMonth ? 200 : 100;

        const filters = {
            username: formFieldsValue.user,
            project: formFieldsValue.projectName,
            type: formFieldsValue.type,
            dateRange,
            date: formFieldsValue.date,
            pageSize,
            sortOrder: formFieldsValue.sortOrder,
            viewMode: formFieldsValue.viewMode,
        };

        props.setTimesheetFilters(filters);
        localStorage.setItem("calendarFilters", JSON.stringify(filters));
        setViewMode(formFieldsValue.viewMode || viewMode);
        props.setCurrentDate(selectedDate.format("YYYY-MM-DD"));
    };

    useEffect(() => {
        const filters = getLocalStorageFilters();

        if (filters) {
            form.setFieldsValue(filters);
        }
    }, [form]);

    return (
        <Header>
            <div className={"input-bar"}>
                <Form name={"TimesheetHeaderForm"} layout={"inline"} form={form}>
                    <Form.Item name={"date"}>
                        <DatePicker
                            suffixIcon={<FaCalendar />}
                            defaultValue={moment(props.currentDate)}
                            onChange={(value) => {
                                console.log(value);
                                if (value) {
                                    form.setFieldsValue({
                                        date: value,
                                    });
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item name="viewMode">
                        <Select
                            placeholder={"View mode"}
                            defaultValue={viewMode}
                            size={"middle"}
                            onChange={(value) => {
                                form.setFieldsValue({
                                    viewMode: value,
                                });
                            }}
                        >
                            {Object.keys(ViewModes).map((key) => (
                                <Option key={key} value={ViewModes[key as keyof typeof ViewModes]}>
                                    {viewModeLabels[key as ViewModes]}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="projectName">
                        <Select
                            placeholder={"Project"}
                            size={"middle"}
                            onChange={(value) => {
                                form.setFieldsValue({ projectName: value as string });
                            }}
                            allowClear
                        >
                            {projects.map((project) => {
                                return (
                                    <Option key={project.id} value={project.name}>
                                        {project.name}
                                    </Option>
                                );
                            })}
                            <Option key={"Research"} value={"Research"}>
                                {"Research"}
                            </Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="type">
                        <Select placeholder={"Type"} allowClear>
                            {types.map((type) => (
                                <Option key={type.id} value={type.name}>
                                    <Tooltip placement="right" title={type.description}>
                                        <span>{type.name}</span>
                                    </Tooltip>
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {!(props.currentUser?.role_id === 3) && (
                        <Form.Item name="user">
                            <Select placeholder={"User"} allowClear>
                                {users.map((user) => {
                                    return (
                                        <Option key={user.id} value={user.email}>
                                            {user.email}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    )}
                    <Form.Item>
                        <IconButton icon={FaSearch} onClick={handleSearchByFilters} />
                    </Form.Item>
                </Form>
                <Button type={"primary"} icon={<FaPlus />} onClick={() => navigateToCreateTimeEntry()}>
                    Add Time Entry
                </Button>
            </div>
        </Header>
    );
};
