import { UseQueryResult, useQuery } from "react-query";
import { Status, getStatusById } from "../../../api/statusApi";
import { useMemo } from "react";

interface ProjectCardVM {
    statusName: string;
}

export const useProjectCardVM = (statusId: number): ProjectCardVM => {
    const statusQuery: UseQueryResult<Status, Error> = useQuery([`Status`, statusId], () => getStatusById(statusId));

    const status: string = useMemo<string>(() => {
        if (statusQuery.isFetched && statusQuery.isSuccess) {
            return statusQuery.data.name;
        }
        return "";
    }, [statusQuery]);

    return {
        statusName: status,
    };
};
