import { client } from "./client";

export interface ProjectRecord {
    id: number;
    name: string;
    description: string;
    status_id: number;
    start_date: string;
    end_date: string;
    is_archived: boolean;
}

interface ProjectsData {
    data: ProjectRecord[];
}

export async function getProjects(username?: string, isArchived?: number): Promise<ProjectRecord[]> {
    const requestBody = {
        username: username,
        is_archived: isArchived,
    };
    const response = await client.get<ProjectsData>("/project", { params: requestBody });
    return response.data.data;
}

export async function getAllProjects(isArchived?: number): Promise<ProjectRecord[]> {
    const requestBody = {
        is_archived: isArchived,
    };
    const response = await client.get<ProjectsData>("/projects", { params: requestBody });
    return response.data.data;
}

export async function getProjectById(id: number): Promise<ProjectRecord> {
    const response = await client.get<ProjectRecord>(`/project/${id}`);
    return response.data;
}

export async function addProject(project: ProjectRecord): Promise<void> {
    await client.post<ProjectRecord>(`/project`, project);
}

export async function editProject(project: ProjectRecord): Promise<void> {
    await client.put<ProjectRecord>(`/project/${project.id}`, project);
}

export async function deleteProject(id: number): Promise<void> {
    await client.delete<ProjectRecord>(`/project/${id}`);
}
