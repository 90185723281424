import moment from "moment";
import React, { ReactNode, createContext, useState } from "react";

export interface CalendarContextType {
    calendarDate: string;
    setCalendarDate: React.Dispatch<React.SetStateAction<string>>;
    viewMode: ViewModes;
    setViewMode: React.Dispatch<React.SetStateAction<ViewModes>>;
}

export enum ViewModes {
    dayGridMonth = "dayGridMonth",
    dayGridWeek = "dayGridWeek",
}

export const CalendarContext = createContext<CalendarContextType | undefined>(undefined);

interface CalendarContextProviderProps {
    children: ReactNode;
}

const CalendarContextProvider: React.FC<CalendarContextProviderProps> = ({ children }) => {
    const [calendarDate, setCalendarDate] = useState<string>(moment().format("YYYY-MM-DD"));
    const [viewMode, setViewMode] = useState<ViewModes>(ViewModes.dayGridMonth);

    const CalendarContextValue: CalendarContextType = {
        calendarDate,
        setCalendarDate,
        viewMode,
        setViewMode,
    };

    return <CalendarContext.Provider value={CalendarContextValue}>{children}</CalendarContext.Provider>;
};

export default CalendarContextProvider;
