import { useMemo } from "react";
import { useLocation } from "react-router-dom";

interface LoginViewVM {
    messageState: LoginMessage;
}

interface LoginMessage {
    message?: string;
}

export const useLoginViewVM = (): LoginViewVM => {
    const location = useLocation();

    const messageState = useMemo(() => {
        return location.state as LoginMessage;
    }, []);

    return {
        messageState,
    };
};
