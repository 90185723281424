import { Form, FormInstance } from "antd";
import { Project } from "../../../data/Project";
import { TimeEntryType } from "../../../data/TimeEntryType";
import { useNavigate } from "react-router-dom";
import { useAppVM } from "../../../AppVM";
import { useQuery, UseQueryResult } from "react-query";
import { getAllProjects, getProjects } from "../../../api/projectApi";
import { useMemo } from "react";
import { getTimeEntryTypes, TimeEntryTypeRecord } from "../../../api/timeEntryTypeApi";
import { defaultTimeEntryTypeColors } from "../../../util/timeEntryTypeColors";
import { getUsers, User } from "../../../api/userApi";
import { ViewModes } from "../../../context/CalendarContextProvider";
import moment from "moment";

interface CalendarHeaderVM {
    projects: Project[];
    types: TimeEntryType[];
    navigateToCreateTimeEntry: () => void;
    form: FormInstance<any>;
    users: User[];
}

export interface CalendarHeaderFormValue {
    user: string;
    projectName: string;
    type: string;
    date?: moment.Moment;
    pageSize: number;
    sortOrder: "asc" | "desc";
    viewMode: ViewModes;
}

export interface CalendarHeaderVMProps {
    currentUser: User | null;
}

export const useCalendarHeaderVM = (props: CalendarHeaderVMProps): CalendarHeaderVM => {
    const navigate = useNavigate();
    const { routes } = useAppVM();
    const [form] = Form.useForm();

    const navigateToCreateTimeEntry = () => {
        navigate(`${routes.timesheet}/create`);
    };

    const projectQuery: UseQueryResult<Project[], Error> = useQuery(
        ["Projects", props.currentUser?.role_id],
        () => {
            if (props.currentUser !== null && props.currentUser.role_id === 3) {
                return getProjects();
            }
            return getAllProjects();
        },
        {
            enabled: props.currentUser !== null,
        }
    );

    const projects: Project[] = useMemo<Project[]>(() => {
        if (projectQuery.isFetched && projectQuery.isSuccess) {
            return projectQuery.data.map((project: Project) => {
                return project;
            });
        }
        return [];
    }, [projectQuery]);

    const typeQuery: UseQueryResult<TimeEntryTypeRecord[], Error> = useQuery("TimeEntryTypes", getTimeEntryTypes);

    const types: TimeEntryType[] = useMemo<TimeEntryType[]>(() => {
        if (typeQuery.isFetched && typeQuery.isSuccess) {
            return typeQuery.data.map((type: TimeEntryTypeRecord, index) => {
                return new TimeEntryType(type, defaultTimeEntryTypeColors[index]);
            });
        }
        return [];
    }, [typeQuery]);

    const usersQuery: UseQueryResult<User[], Error> = useQuery(
        "Users",
        () => {
            return getUsers();
        },
        { enabled: !(props.currentUser?.role_id === 3) }
    );

    const users: User[] = useMemo<User[]>(() => {
        if (usersQuery.isFetched && usersQuery.isSuccess) {
            return usersQuery.data.map((user: User) => {
                return user;
            });
        }
        return [];
    }, [usersQuery]);

    return {
        projects,
        types,
        navigateToCreateTimeEntry,
        form,
        users,
    };
};
