import React from "react";
import { FormHeader } from "../../FormHeader/View/FormHeader";
import { Button, Checkbox, DatePicker, Form, Input, Select } from "antd";
import { ProjectFormValue, useProjectFormVM } from "../ViewModel/ProjectFormVM";
import { FaCalendar, FaPlus } from "react-icons/fa";
import { convertDateStringToMoment, convertMomentToDateString } from "../../../util/momentDateUtil";
import { TeamCard } from "../../TeamCard/View/TeamCard";
import { Team } from "../../../api/teamApi";

export const ProjectForm = (): JSX.Element => {
    const { TextArea } = Input;
    const { Option } = Select;
    const {
        project,
        statuses,
        addProjectMutation,
        editProjectMutation,
        isEditing,
        form,
        getCurrentStatusName,
        getStatusIdFromName,
        navigateToProjects,
        navigateToProjectMemberForm,
        navigateToTeamForm,
        teams,
        deleteTeamMutation,
        navigateToEditTeam,
    } = useProjectFormVM();

    return (
        <>
            <FormHeader label={isEditing ? "Edit" : "Create"} onReturn={navigateToProjects} />
            <div className={"form-container"}>
                <div className={"form-centered"}>
                    <Form
                        name="projectForm"
                        form={form}
                        onFinish={() => {
                            const formFieldsValue = form.getFieldsValue() as ProjectFormValue;
                            if (isEditing) {
                                editProjectMutation.mutate({
                                    ...project,
                                    name: formFieldsValue.name,
                                    description: formFieldsValue.description,
                                    start_date: formFieldsValue.startDate,
                                    status_id: formFieldsValue.statusId,
                                    end_date: formFieldsValue.endDate,
                                    is_archived: formFieldsValue.isArchived,
                                });
                            } else {
                                addProjectMutation.mutate({
                                    id: 0,
                                    name: formFieldsValue.name,
                                    description: formFieldsValue.description,
                                    status_id: formFieldsValue.statusId,
                                    start_date: formFieldsValue.startDate,
                                    end_date: formFieldsValue.endDate,
                                    is_archived: false,
                                });
                            }
                            navigateToProjects();
                        }}
                    >
                        <Form.Item name="name" rules={[{ required: true, message: "Please input your work project!" }]}>
                            <h3>Project</h3>
                            <Input
                                placeholder={"Project"}
                                size={"middle"}
                                defaultValue={isEditing ? project.name : undefined}
                                onChange={(value) => {
                                    form.setFieldsValue({ name: value.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            rules={[{ required: true, message: "Please input your project description!" }]}
                        >
                            <h3>Description</h3>
                            <TextArea
                                rows={5}
                                placeholder={"Description"}
                                defaultValue={isEditing ? project.description : ""}
                                onChange={(value) => {
                                    form.setFieldsValue({ description: value.target.value });
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="startDate"
                            rules={[{ required: true, message: "Please input your project's start date!" }]}
                        >
                            <h3>Start date</h3>

                            <DatePicker
                                className={"date-picker"}
                                suffixIcon={<FaCalendar />}
                                defaultValue={
                                    isEditing
                                        ? convertDateStringToMoment(project.startDateTimeStamp, "YYYY/MM/DD")
                                        : undefined
                                }
                                onChange={(momentDate) => {
                                    if (momentDate) {
                                        const startDate = convertMomentToDateString(momentDate);
                                        form.setFieldsValue({ startDate: startDate });
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="endDate"
                            rules={[{ required: true, message: "Please input your project's end date!" }]}
                        >
                            <h3>End date</h3>

                            <DatePicker
                                className={"date-picker"}
                                suffixIcon={<FaCalendar />}
                                defaultValue={
                                    isEditing
                                        ? convertDateStringToMoment(project.endDateTimeStamp, "YYYY/MM/DD")
                                        : undefined
                                }
                                onChange={(momentDate) => {
                                    if (momentDate) {
                                        const endDate = convertMomentToDateString(momentDate);
                                        form.setFieldsValue({ endDate: endDate });
                                    }
                                }}
                            />
                        </Form.Item>
                        {/* This check is necessary because a default value of the Select can be given only for initial render.
                            Since only the status ID is available, we have to wait to fetch all of the statuses(needed as options)
                            and get the current status name.
                         */}
                        {isEditing && statuses.length > 0 && (
                            <Form.Item
                                name={"statusId"}
                                rules={[{ required: true, message: "Please input your project's status" }]}
                            >
                                <h3>Status</h3>

                                <Select
                                    placeholder={"Status"}
                                    defaultValue={getCurrentStatusName(project.statusId)}
                                    onChange={(status) => {
                                        form.setFieldsValue({ statusId: getStatusIdFromName(status) });
                                    }}
                                >
                                    {statuses.map((status) => {
                                        return (
                                            <Option key={status.id} value={status.name}>
                                                {status.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        )}
                        {!isEditing && (
                            <Form.Item
                                name={"statusId"}
                                rules={[{ required: true, message: "Please input your project's status" }]}
                            >
                                <h3>Status</h3>

                                <Select
                                    placeholder={"Status"}
                                    onChange={(status) => {
                                        form.setFieldsValue({ statusId: getStatusIdFromName(status as string) });
                                    }}
                                >
                                    {statuses.map((status) => {
                                        return (
                                            <Option key={status.id} value={status.name}>
                                                {status.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        )}
                        {isEditing && (
                            <>
                                <Form.Item name="isArchived" valuePropName="checked">
                                    <h3>Archived</h3>
                                    <Checkbox
                                        defaultChecked={project.isArchived}
                                        onChange={(e) => form.setFieldsValue({ isArchived: e.target.checked })}
                                    />
                                </Form.Item>
                            </>
                        )}
                        <Form.Item>
                            <div className={"inline-buttons-container"}>
                                <Button type={"primary"} htmlType={"submit"}>
                                    Save
                                </Button>
                                <Button onClick={navigateToProjects}>Cancel</Button>
                            </div>
                        </Form.Item>
                    </Form>
                    {isEditing && (
                        <div>
                            <h2>Teams</h2>
                            <div className="projects-form-teams-button-container">
                                <Button type={"primary"} icon={<FaPlus />} onClick={() => navigateToTeamForm(project)}>
                                    Add Team
                                </Button>
                                <Button
                                    type={"default"}
                                    icon={<FaPlus />}
                                    onClick={() => navigateToProjectMemberForm(project)}
                                >
                                    Add User
                                </Button>
                            </div>
                            <div>
                                {teams.length > 0 ? (
                                    teams.map((team: Team) => (
                                        <TeamCard
                                            key={team.id}
                                            team={team}
                                            onDelete={() => deleteTeamMutation.mutate(team.id)}
                                            onEdit={() => navigateToEditTeam(team)}
                                        />
                                    ))
                                ) : (
                                    <p>No teams</p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
