import React, { FC, useContext, useState } from "react";
import { TimesheetHeader } from "../../../components/TimesheetHeader/View/TimesheetHeader";
import { Pagination, Spin } from "antd";
import { TimeEntryCard } from "../../../components/TimeEntryCard/View/TimeEntryCard";
import { useTimesheetVM } from "../ViewModel/TimesheetVM";
import { useAppVM } from "../../../AppVM";
import { ScrollingBody } from "../../../components/ScrollingBody/View/ScrollingBody";
import { UserContext, UserContextType } from "../../../context/UserContextProvider";

export const TimesheetView: FC = () => {
    const { user } = useContext(UserContext) as UserContextType;

    const [currentPage, setCurrentPage] = useState<number>(1);

    const {
        timeEntries,
        deleteTimeEntryMutation,
        loading,
        navigate,
        getTimeEntryTypeColor,
        durationData,
        timeEntriesTotal,
        setTimesheetFilters,
        timesheetFilters,
    } = useTimesheetVM({ user: user, page: currentPage });
    const { routes } = useAppVM();

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <ScrollingBody
            header={
                <TimesheetHeader
                    durationData={durationData}
                    setTimesheetFilters={setTimesheetFilters}
                    timesheetFilters={timesheetFilters}
                />
            }
            footer={
                <div className={"pagination-bottom-container"}>
                    <Pagination
                        total={timeEntriesTotal}
                        current={currentPage}
                        pageSize={timesheetFilters.pageSize}
                        onChange={handlePageChange}
                    />
                </div>
            }
        >
            <div className="timesheet-body-container">
                {loading && (
                    <div className={"spinner-container"}>
                        <Spin />
                    </div>
                )}
                {timeEntries.map((item, index) => {
                    return (
                        <TimeEntryCard
                            key={index}
                            timeEntry={item}
                            color={getTimeEntryTypeColor(item)}
                            onDelete={deleteTimeEntryMutation.mutate}
                            onEdit={() =>
                                navigate(`${routes.timesheet}/edit/${item.id}`, {
                                    state: {
                                        ...item,
                                    },
                                })
                            }
                        />
                    );
                })}
            </div>
        </ScrollingBody>
    );
};
